@use '@angular/material' as mat;
@mixin simple-dialog-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    .confirm {
        &-title-wrap {
            background-color: mat.get-color-from-palette($background, card);
        }
    }
}
