@mixin chatbot-dialog-common() {
    .rpc-chatbot {
        &-dialog {
            width: 100% !important;
            max-width: 100% !important;
            margin: 0 !important;
            @include media-breakpoint("gt-xs") {
                width: auto !important;
                margin-bottom: 20px !important;
                margin-right: 15px !important;
            }
            .mat-dialog-container {
                border-radius: 20px 20px 0 0;
                padding: 10px 0px 8px;
                height: calc(var(--vh) * 0.85);
                @include media-breakpoint("gt-xs") {
                    border-radius: 20px;
                    padding-bottom: 14px;
                }
                @media screen and (min-height: 300px) {
                    padding-bottom: 8px;
                }
                @media screen and (min-height: 800px) {
                    padding-bottom: 14px;
                    height: calc(var(--vh) * 0.75);
                }
                @media screen and (min-height: 900px) {
                    height: calc(var(--vh) * 0.60);
                }
            }
        }
    }
}
