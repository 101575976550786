@import "src/app/modules/map/components/price-marker/price-marker.common";
@import "src/app/modules/map/components/map-cluster/map-cluster.common";
@import "src/app/modules/map/components/map-layout/map-layout.common";
@import "src/app/modules/map/components/map-controls/map-controls.common";

$max-height: 700px;

/* Fix Google Map Fullscreen Mode */
html {
    &.rpc-ld-page-wrap {
        body {
            &.map-fullscreen-wrap {
                grid-template-rows: minmax(0, 1fr);
                .rpc-ld-toolbar {
                    display: none;
                }
                .rpc-ld-body {
                    position: static !important;
                    &-map {
                        &-box {
                            position: static !important;
                            google-map {
                                .map-container {
                                    position: absolute !important;
                                    top: 0;
                                    right: 0;
                                    bottom: 0;
                                    left: 0;
                                    z-index: 20;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
body {
    &.map-fullscreen-wrap {
        &:not(.hybrid-mode) {
            .global {
                &--toolbar {
                    @include media-breakpoint("gt-md") {
                        grid-template-rows: minmax(0, 1fr);
                    }
                }
                &-toolbar {
                    @include media-breakpoint("gt-md") {
                        display: none;
                    }
                }
            }
        }
        &:not(.not-authorized) {
            .global {
                .global-content {
                    padding-bottom: 0;
                }
            }
        }
        &.map-fullscreen-wrap--sided-folder,
        &.map-fullscreen-wrap--sided-search {
            on-market,
            internal-listings {
                .listings-wrap {
                    &.listings-wrap--sided {
                        @include media-breakpoint("gt-md") {
                            grid-template-rows: minmax(0, 1fr);
                            .listings {
                                &-anchor,
                                &-top {
                                    display: none;
                                }
                                &-body {
                                    padding: 0;
                                    margin: 0;
                                }
                                &-sided {
                                    display: block;
                                    &-inner {
                                        display: none;
                                    }
                                    &-map {
                                        position: static;
                                        display: block;
                                        width: 100% !important;
                                        height: 100%;
                                        &:before {
                                            display: none;
                                        }
                                        &-wrap {
                                            display: block;
                                            width: 100%;
                                            max-width: none;
                                            height: 100%;
                                        }
                                    }
                                    &-btn {
                                        display: none;
                                    }
                                    .as-split-gutter {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.map-fullscreen-wrap--folder,
        &.map-fullscreen-wrap--search {
            on-market,
            internal-listings {
                .listings-wrap {
                    &:not(.listings-wrap--sided) {
                        grid-template-rows: minmax(0, 1fr);
                        .listings {
                            &-anchor,
                            &-top {
                                display: none;
                            }
                            &-body {
                                padding: 0;
                                margin: 0;
                            }
                            &-sided {
                                display: block;
                                &-inner {
                                    display: none;
                                }
                                &-map {
                                    position: static;
                                    display: block;
                                    width: 100% !important;
                                    height: 100%;
                                    &:before {
                                        display: none;
                                    }
                                    &-wrap {
                                        display: block;
                                        width: 100%;
                                        max-width: none;
                                        height: 100%;
                                    }
                                }
                                &-btn {
                                    display: none;
                                }
                                .as-split-gutter {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        .global {
            &-footer,
            &-bottombar {
                display: none;
            }
        }
    }
}

body {
    @include price-marker-common();
    @include map-cluster-common();
    @include map-layout-common();
    @include map-controls-common();
}

.gm-style {
    .gm-style-iw {
        padding: 0 !important;
        max-width: 280px !important;
        max-height: 380px !important;
        border-radius: 10px;
        @media screen and (max-height: $max-height) {
            max-height: 220px !important;
            max-width: 280px !important;
        }
        &-c,
        &-d {
            overflow: visible !important;
            clear: both;
            border-radius: 10px;
        }
        &-d {
            position: relative;
            z-index: 1;
            min-width: 250px !important;
            max-height: 380px !important;

            @media screen and (max-height: $max-height) {
                max-height: 220px !important;
                max-width: 280px !important;
            }
            & + button {
                border-radius: 50% !important;
                opacity: 1 !important;
                z-index: 3;
                top: -12px !important;
                right: -12px !important;
                transition:
                    background-color .5s cubic-bezier(0.35, 0, 0.25, 1),
                    color .5s cubic-bezier(0.35, 0, 0.25, 1);
                &:before,
                &:after {
                    position: absolute;
                    display: block;
                    content: '';
                    top: 14px;
                    left: 7px;
                    width: 16px;
                    border-top-width: 2px;
                    border-top-style: solid;
                    border-top-color: currentColor;
                    z-index: 5;
                }
                &:before {
                    transform: rotate(45deg);
                }
                &:after {
                    transform: rotate(-45deg);
                }
                span {
                    display: none !important;
                }
            }
        }
        &-t {
            &:after {
                top: -1px !important;
            }
        }
        &-tc {
            &:after {
                top: 14px;
            }
        }
        &-ch {
            display: none;
            &r {
                position: absolute;
                display: flex;
                top: -16px;
                right: -16px;
                z-index: 10;
                .gm-ui-hover-effect {
                    display: flex !important;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    width: 32px !important;
                    height: 32px !important;
                    opacity: 1;
                    & > span {
                        margin: 0 !important;
                        transition: background-color .3s linear;
                        will-change: background-color;
                    }
                }
            }
        }
    }
    .info-window-wrap {
        .search-results-view-gallery {
            max-height: 394px;
            overflow-y: auto;
            overscroll-behavior-y: none;
            overflow-anchor: none;
            -ms-overflow-style: none;
            touch-action: auto;
            -ms-touch-action: auto;
            -webkit-overflow-scrolling: touch;
            border-radius: 10px;
            @media screen and (max-height: $max-height) {
                max-height: 220px;
            }
            &-item {
                &:not(:last-of-type) {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
// Point of interest on Google map
.poi-info-window {
    padding: 12px;
    border-radius: 10px;
    .title {
        width: 100%;
        margin-bottom: 10px;
    }
    .address {
        margin-bottom: 5px;
        padding-bottom: 10px;
    }
}

.cm-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    margin: 5px;
    cursor: pointer;
    &--rectangle,
    &--circle {
        position: relative;
        &:after {
            display: block;
            content:'';
            width: 26px;
            height: 26px;
            border-width: 1.5px;
            border-style: solid;
        }
    }
    &--circle {
        &:after {
            border-radius: 50%;
        }
    }
    &--delete {
        display: none;
        &.cm-btn--show {
            display: flex;
        }
        .rpci {
            font-size: 30px !important;
        }
    }
    .rpci {
        display: block;
        font-size: 24px;
        width: 1em;
        height: 1em;
        stroke-width: 0;
        stroke: currentColor;
        fill: currentColor;
    }
}
