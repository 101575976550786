@mixin internal-listing-details-toolbar-common() {
    &:not(.hybrid-mode) {
        .ld-toolbar {
            @include media-breakpoint("gt-md") {
                grid-template-columns: 96px minmax(0, 1fr) 96px;
            }
            &-back {
                .rpc-btn-link {
                    @include media-breakpoint("gt-md") {
                        padding: 0 16px;
                    }
                    &-text {
                        @include media-breakpoint("gt-md") {
                            display: block;
                        }
                    }
                }
            }
            &-share {
                &--mob {
                    @include media-breakpoint("gt-md") {
                        display: none;
                    }
                }
                &--desc {
                    @include media-breakpoint("gt-md") {
                        display: block;
                    }
                }
            }
        }
    }
    .ld {
        &-toolbar {
            &-back {
                .rpc-btn-link {
                    min-width: 40px;
                    padding: 0;
                    &-icon {
                        font-size: 24px;
                    }
                    &-text {
                        display: none;
                    }
                }
            }
            &-nav {
                .rpc-btn-link {
                    padding: 0;
                    min-width: auto;
                    width: 40px;
                    &-icon {
                        font-size: 24px;
                    }
                }
            }
            &-right {
                .rpc-menu-icon-btn {
                    border-radius: 4px;
                    &-icon {
                        font-size: 24px;
                    }
                }
            }
        }
    }
}
