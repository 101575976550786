@use '@angular/material' as mat;
@mixin rpc-button($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-btn-link {
        &:not(.mat-button-disabled) {
            &.active,
            &:hover {
                .rpc-btn-link-text {
                    color: mat.get-color-from-palette($foreground, text);
                }
            }
            .rpc-btn-link {
                &-icon {
                    color: mat.get-color-from-palette($accent, default);
                    --main-color: #{mat.get-color-from-palette($accent, default)};
                    --text-color: #{mat.get-color-from-palette($accent, default-contrast)};
                }
                &-text {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                }
            }
            &.active {
                &.pick {
                    .rpc-btn-link {
                        &-icon {
                            color: var(--picklist-color);
                            --main-color: var(--picklist-color);
                            --text-color: var(--picklist-color);
                        }
                    }
                }
                &.liked {
                    .rpc-btn-link {
                        &-icon {
                            color: var(--like-color);
                            --main-color: var(--like-color);
                            --text-color: var(--like-color);
                        }
                    }
                }
                &.disliked {
                    .rpc-btn-link {
                        &-icon {
                            color: var(--dislike-color);
                            --main-color: var(--dislike-color);
                            --text-color: var(--dislike-color);
                        }
                    }
                }
                &.shown {
                    .rpc-btn-link {
                        &-icon {
                            color: var(--visit-color);
                            --main-color: var(--visit-color);
                            --text-color: var(--visit-color);
                        }
                    }
                }
            }
        }
        &.mat-flat-button {
            background: mat.get-color-from-palette($background, hover);
            border-color: mat.get-color-from-palette($background, hover);
            &.active,
            &:hover {
                background: mat.get-color-from-palette($background, focused-button);
                border-color: mat.get-color-from-palette($background, focused-button);
                .rpc-btn-link {
                    &-text {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                    &-icon {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                        --main-color: #{mat.get-color-from-palette($foreground, secondary-text)};
                        --text-color: #{mat.get-color-from-palette($background, card)};
                    }
                }
            }
            &.mat {
                &-accent {
                    &:not(.mat-button-disabled) {
                        background: mat.get-color-from-palette($accent, default);
                        border-color: mat.get-color-from-palette($accent, default);
                        &.active,
                        &:hover {
                            background: mat.get-color-from-palette($accent, 400);
                            border-color: mat.get-color-from-palette($accent, 400);
                            .rpc-btn-link {
                                &-icon {
                                    --text-color: #{mat.get-color-from-palette($accent, 400)};
                                }
                                &-text {
                                    color: mat.get-color-from-palette($accent, default-contrast);
                                }
                            }
                        }
                        .rpc-btn-link {
                            &-text {
                                color: mat.get-color-from-palette($accent, default-contrast);
                            }
                            &-icon {
                                color: mat.get-color-from-palette($accent, default-contrast);
                                --main-color: #{mat.get-color-from-palette($accent, default-contrast)};
                                --text-color: #{mat.get-color-from-palette($accent, default)};
                            }
                        }
                    }
                }
                &-primary {
                    &:not(.mat-button-disabled) {
                        background: mat.get-color-from-palette($primary, default);
                        border-color: mat.get-color-from-palette($primary, default);
                        &.active,
                        &:hover {
                            background: mat.get-color-from-palette($primary, 400);
                            border-color: mat.get-color-from-palette($primary, 400);
                            .rpc-btn-link {
                                &-icon {
                                    --text-color: #{mat.get-color-from-palette($primary, 400)};
                                }
                                &-text {
                                    color: mat.get-color-from-palette($primary, default-contrast);
                                }
                            }
                        }
                        .rpc-btn-link {
                            &-text {
                                color: mat.get-color-from-palette($primary, default-contrast);
                            }
                            &-icon {
                                color: mat.get-color-from-palette($primary, default-contrast);
                                --main-color: #{mat.get-color-from-palette($primary, default-contrast)};
                                --text-color: #{mat.get-color-from-palette($primary, default)};
                            }
                        }
                    }
                }
                &-warn {
                    &:not(.mat-button-disabled) {
                        background: mat.get-color-from-palette($warn, default);
                        border-color: mat.get-color-from-palette($warn, default);
                        &.active,
                        &:hover {
                            background: mat.get-color-from-palette($warn, 400);
                            border-color: mat.get-color-from-palette($warn, 400);
                            .rpc-btn-link {
                                &-icon {
                                    --text-color: #{mat.get-color-from-palette($warn, 400)};
                                }
                                &-text {
                                    color: mat.get-color-from-palette($warn, default-contrast);
                                }
                            }
                        }
                        .rpc-btn-link {
                            &-text {
                                color: mat.get-color-from-palette($warn, default-contrast);
                            }
                            &-icon {
                                color: mat.get-color-from-palette($warn, default-contrast);
                                --main-color: #{mat.get-color-from-palette($warn, default-contrast)};
                                --text-color: #{mat.get-color-from-palette($warn, default)};
                            }
                        }
                    }
                }
            }
            &.mat-button-disabled {
                &.active,
                &:hover {
                    .rpc-btn-link {
                        &-icon {
                            --text-color: #{mat.get-color-from-palette($foreground, disabled)} !important;
                        }
                        &-text {
                            color: mat.get-color-from-palette($foreground, disabled) !important;
                        }
                    }
                }
                .rpc-btn-link {
                    &-icon {
                        color: mat.get-color-from-palette($foreground, disabled) !important;
                        --main-color: #{mat.get-color-from-palette($foreground, disabled)} !important;
                        --text-color: #{mat.get-color-from-palette($accent, default-contrast)} !important;
                    }
                    &-text {
                        color: mat.get-color-from-palette($foreground, disabled) !important;
                    }
                }
            }
            .rpc-btn-link {
                &-text {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                }
                &-icon {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                    --main-color: #{mat.get-color-from-palette($foreground, secondary-text)};
                    --text-color: #{mat.get-color-from-palette($background, card)};
                }
            }
        }
        &.mat-stroked-button {
            &.mat {
                &-accent {
                    &:not(.mat-button-disabled) {
                        border-color: mat.get-color-from-palette($accent, default);
                        .rpc-btn-link {
                            &-text {
                                color: mat.get-color-from-palette($accent, default);
                            }
                        }
                    }
                }
                &-primary {
                    &:not(.mat-button-disabled) {
                        border-color: mat.get-color-from-palette($primary, default);
                        .rpc-btn-link {
                            &-text {
                                color: mat.get-color-from-palette($primary, default);
                            }
                        }
                    }
                }
                &-warn {
                    &:not(.mat-button-disabled) {
                        border-color: mat.get-color-from-palette($warn, default);
                        .rpc-btn-link {
                            &-text {
                                color: mat.get-color-from-palette($warn, default);
                            }
                        }
                    }
                }
            }
        }
        &.mat-button {
            &.mat {
                &-accent {
                    &:not(.mat-button-disabled) {
                        .rpc-btn-link {
                            &-icon {
                                color: mat.get-color-from-palette($accent, default);
                                --main-color: #{mat.get-color-from-palette($accent, default)};
                                --text-color: #{mat.get-color-from-palette($accent, default-contrast)};
                            }
                            &-text {
                                color: mat.get-color-from-palette($accent, default);
                            }
                        }
                    }
                }
                &-primary {
                    &:not(.mat-button-disabled) {
                        .rpc-btn-link {
                            &-icon {
                                color: mat.get-color-from-palette($primary, default);
                                --main-color: #{mat.get-color-from-palette($primary, default)};
                                --text-color: #{mat.get-color-from-palette($primary, default-contrast)};
                            }
                            &-text {
                                color: mat.get-color-from-palette($primary, default);
                            }
                        }
                    }
                }
                &-warn {
                    &:not(.mat-button-disabled) {
                        .rpc-btn-link {
                            &-icon {
                                color: mat.get-color-from-palette($warn, default);
                                --main-color: #{mat.get-color-from-palette($warn, default)};
                                --text-color: #{mat.get-color-from-palette($warn, default-contrast)};
                            }
                            &-text {
                                color: mat.get-color-from-palette($warn, default);
                            }
                        }
                    }
                }
            }
        }
    }
}
