@mixin m-calculator-dialog-common() {
    .mc {
        &-dialog {
            position: absolute !important;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            max-width: none !important;
            @include media-breakpoint("gt-xs") {
                position: static !important;
                max-width: 90% !important;
            }
            .mat-dialog-container {
                padding: 0;
                border-radius: 0;
                overflow: hidden;
                overflow-y: auto;
                @include media-breakpoint("gt-xs") {
                    border-radius: 10px;
                    width: 100%;
                    max-width: 720px;
                    max-height: calc(var(--vh) * 0.85);
                }
            }
        }
        &-header-action {
            .rpc-btn-link {
                padding: 0;
                min-width: 40px;
                @include media-breakpoint("gt-xs") {
                    padding: 0 16px;
                }
                &-text {
                    display: none;
                    @include media-breakpoint("gt-xs") {
                        display: block;
                    }
                }
            }
        }
        &-form-footer {
            .rpc-btn-link {
                display: block;
                width: 100%;
            }
        }
    }
}