@mixin navigation-panel-common() {
    .mobile-bottombar {
        &-btn {
            .rpc-icon-btn {
                display: block;
                height: 55px;
                width: 100%;
                border-radius: 0;
                &.badge-wrap {
                    .badge-count {
                        top: 7px;
                        right: auto;
                        left: 50%;
                        transform: translateX(2px);
                    }
                }
            }
        }
    }
}