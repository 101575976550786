@mixin agent-selection-common() {
    &:not(.hybrid-mode) {
        .agent-selection-page {
            &-wrap {
                @include media-breakpoint("gt-md") {
                    grid-template-rows: 100%;
                }
            }
            &-toolbar {
                @include media-breakpoint("gt-md") {
                    display: none;
                }
            }
        }
    }
}
