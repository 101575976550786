@use '@angular/material' as mat;
@mixin agent-box($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .agent-info {
        border-color: mat.get-color-from-palette($foreground, divider);
        background-color: mat.get-color-from-palette($background, background);
        &--active {
            border-color: mat.get-color-from-palette($accent, default);
            background-color: mat.get-color-from-palette($accent, default, 0.1);
        }
        &-status {
            background-color: rgb(255 255 255 / 100%);
            color: mat.get-color-from-palette($accent, default);
            &:before {
                background-color: mat.get-color-from-palette($accent, default, 0.1);
            }
        }
        &-company,
        &-login {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
        &-footer {
            &-item {
                color: mat.get-color-from-palette($foreground, disabled);
                background-color: mat.get-color-from-palette($background, hover);
                &.agent-info-footer-item--active {
                    color: mat.get-color-from-palette($accent, default);
                    background-color: rgb(255 255 255 / 100%);
                    &:before {
                        background-color: mat.get-color-from-palette($accent, default, .2);
                    }
                    &.agent-info-footer-item {
                        &--liked {
                            color: var(--like-color);
                            &:before {
                                background-color: var(--like-color-200);
                            }
                        }

                    }
                }
                &:after {
                    @if (mat.get-color-from-palette($primary, default) == mat.get-color-from-palette($accent, default)) {
                        background-color: var(--has-new-comments);
                    } @else {
                        background-color: mat.get-color-from-palette($accent, default);
                    }
                }
            }
        }
    }
}