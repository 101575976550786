@mixin profile-settings-common() {
    .deactivate-account-modal {
        max-width: 90% !important;
        .mat-dialog-container {
            width: 100%;
            max-width: 500px;
        }
    }
    .profile-settings {
        &-section {
            &-block {
                .deactivate {
                    .rpc-btn-link {
                        display: block;
                        width: 100%;
                        @include media-breakpoint("gt-xs") {
                            width: auto;
                        }
                    }
                }
                .theme-toggle {
                    .mat-slide-toggle {
                        &-label {
                            display: grid;
                            grid-template-columns: minmax(0, 1fr) auto;
                            column-gap: 4px;
                            align-items: start;
                        }
                        &-content {
                            white-space: initial;
                        }
                    }
                    .mat-slide-toggle-bar {
                        margin: 4px 0 0;
                    }
                }
            }
        }
    }
}