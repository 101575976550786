@mixin rpc-select-common() {
    .rpc-select {
        &.rpc-select--without-label {
            &.mat-form-field {
                &.field {
                    &-fill,
                    &-legacy {
                        &.mat-form-field-type-mat-select {
                            .mat-form-field-infix {
                                border: none !important;
                                padding: 10px 0 19px !important;
                            }
                        }
                    }
                }
            }
        }
        &.mat-form-field-has-label {
            &.mat-form-field {
                &.field {
                    &-fill,
                    &-legacy {
                        &.mat-form-field-type-mat-select {
                            .mat-form-field-infix {
                                border: none !important;
                                padding: 10px 0 19px !important;
                            }
                            .mat-form-field-label-wrapper {
                                top: -.48em;
                            }
                        }
                        &.mat-form-field-can-float {
                            &.mat-form-field-should-float {
                                &.mat-form-field-type-mat-select {
                                    .mat-form-field-infix {
                                        border-top: 0.84375em solid transparent !important;
                                        padding: 0.25em 0 0.75em 0 !important;
                                    }
                                    .mat-form-field-label-wrapper {
                                        top: -1.48em;
                                    }
                                }
                                .mat-form-field-label {
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.mat-form-field {
            &-disabled {
                .mat-form-field {
                    &-flex {
                        pointer-events: none;
                    }
                    &-autofill-control {
                        opacity: 0.5;
                    }
                }
            }
            &-subscript-wrapper {
                position: static;
                padding: 0 !important;
            }
            &.field {
                &-fill,
                &-legacy {
                    &.mat-form-field-can-float {
                        &.mat-form-field-should-float {
                            .mat-form-field-label {
                                margin-top: -0.5em;
                            }
                        }
                        .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
                            margin-top: -0.5em;
                        }
                    }
                    .mat-form-field {
                        &-flex {
                            padding: 8px 15px 0;
                        }
                        &-underline {
                            position: static;
                        }
                        &-ripple {
                            height: 1px;
                        }
                        &-label {
                            margin-top: 0
                        }
                    }
                }
                &-outline {
                    .mat-form-field {
                        &-flex {
                            padding: 0 1.2em 0 1.2em;
                        }
                        &-infix {
                            padding: 0.25em 0.25em 0.75em !important;
                        }
                        &-outline {
                            &-start,
                            &-end,
                            &-gap {
                                border-width: 1px;
                            }
                            &-start {
                                min-width: 5px;
                            }
                        }
                        &-label {
                            top: 1.35em !important;
                        }
                    }
                }
            }
            &-should-float {
                &.field {
                    &-outline {
                        .mat-form-field {
                            &-label {
                                margin-top: 3px !important;
                            }
                        }
                    }
                }
            }
            .mat {
                &-select {
                    &-value {
                        &-text {
                            display: block;
                        }
                    }
                    &-arrow {
                        &-wrapper {
                            transform: translateY(0) !important;
                        }
                    }
                    &-trigger {
                        display: flex !important;
                    }
                }
            }
            mat-select-trigger {
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
        }
        &-option {
            .mat-option-text {
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
        }
    }
}
