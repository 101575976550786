@mixin base-listings-common() {
    &.hybrid-mode {
        .listings-wrap {
            .scroll-to-top-wrap {
                bottom: 65px;
            }
        }
    }
    &:not(.hybrid-mode) {
        .listings {
            &-wrap {
                &--top {
                    &.listings-wrap--at-bottom {
                        .listings-sided-map {
                            @include media-breakpoint("gt-md") {
                                top: 300px;
                                height: calc(var(--vh) - 350px);
                            }
                        }
                        .as-split-gutter {
                            @include media-breakpoint("gt-md") {
                                top: 300px;
                            }
                        }
                    }
                    &.listings-wrap {
                        .listings-sided-btn {
                            @include media-breakpoint("gt-md") {
                                top: 300px;
                            }
                        }
                    }
                    .listings-sided {
                        &-map {
                            @include media-breakpoint("gt-md") {
                                top: 300px;
                                height: calc(var(--vh) - 350px);
                            }
                        }
                    }
                    .as-split-gutter {
                        @include media-breakpoint("gt-md") {
                            top: 300px;
                        }
                    }
                }
                &--at-bottom {
                    .listings-sided-map {
                        @include media-breakpoint("gt-md") {
                            height: calc(var(--vh) - 250px);
                        }
                    }
                }
                &.listings-wrap {
                    &--top {
                        .listings-secondary-toolbar {
                            @include media-breakpoint("gt-md") {
                            }
                        }
                    }
                    &--minimized {
                        .listings-secondary-toolbar {
                            @include media-breakpoint("gt-md") {
                                padding: 0;
                            }
                        }
                    }
                }
                .listings-sided-btn {
                    @include media-breakpoint("gt-md") {
                        top: 200px;
                    }
                }
            }
            &-toolbar {
                @include media-breakpoint("gt-md") {
                    display: none;
                }
            }
            &-secondary-toolbar {
                @include media-breakpoint("gt-md") {
                    top: 0;
                    padding: 0;
                }
            }
            &-title {
                @include media-breakpoint("gt-md") {
                    display: none;
                }
            }
            &-sided {
                &-inner {
                    @include media-breakpoint("gt-md") {
                        min-width: 340px;
                    }
                    &--empty {
                        @include media-breakpoint("gt-md") {
                            grid-template-rows: calc(100% - 20px);
                        }
                    }
                }
                &-map {
                    @include media-breakpoint("gt-md") {
                        top: 200px;
                        height: calc(var(--vh) - 250px);
                    }
                }
                .as-split-gutter {
                    @include media-breakpoint("gt-md") {
                        top: 200px;
                        height: 100%;
                    }
                }
            }
        }
    }
    .listings {
        &-wrap {
            &--folder {
                .listings-map {
                    map-controls {
                        display: none;
                    }
                }
            }
            &--at-bottom {
                .listings-sided-map {
                    height: calc(var(--vh) - 280px);
                }
            }
            .listings-sided-btn {
                @include media-breakpoint("gt-md") {
                    display: block;
                    top: 172px;
                }
            }
            .scroll-to-top-wrap {
                &.upper {
                    &.scroll-to-top-wrap--up {
                        @include media-breakpoint("gt-xs") {
                            bottom: 110px;
                        }
                        @include media-breakpoint("gt-sm") {
                            bottom: 50px;
                        }
                    }
                }
            }
        }
        &-sided {
            &-btn {
                .rpc-btn-link {
                    padding: 0 10px;
                    min-width: 42px;
                    height: 42px;
                    line-height: 42px;
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;
                    transition: box-shadow 0.2s ease-in-out;
                }
                .badge-wrap{
                    .badge-count {
                        top: -7px;
                        right: auto;
                        left: -7px;
                    }
                }
            }
            .as-split-gutter {
                order: 2 !important;
                display: flex;
                justify-content: flex-end !important;
                align-items: center;
                height: 100% !important;
                transform: translateX(20px);
                z-index: 12;
                &-icon {
                    display: none;
                }
            }
        }
    }
}
