@mixin price-change-info-common() {
    .price-dropdown {
        &-wrap {
            max-width: 320px;
            @include media-breakpoint("gt-xs") {
                max-width: 340px;
            }
        }
    }
}
