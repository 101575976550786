@use '@angular/material' as mat;
@mixin toolbar-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .toolbar {
        background: mat.get-color-from-palette($background, card);
        border-bottom-color: mat.get-color-from-palette($foreground, divider);
        .toolbar {
            &-knowledge {
                .rpc-icon-btn {
                    &:not(.mat-button-disabled) {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                        &:hover,
                        &.active {
                            color: mat.get-color-from-palette($accent, default);
                        }
                        .rpc-icon-btn-icon {
                            color: currentColor;
                        }
                    }
                }
            }
            &-agent {
                &-btn {
                    background-color: mat.get-color-from-palette($background, card);
                    border-color: mat.get-color-from-palette($foreground, divider);
                    &:hover {
                        background-color: mat.get-color-from-palette($background, background);
                        .toolbar-agent-btn {
                            &-text {
                                color: mat.get-color-from-palette($foreground, text);
                            }
                        }
                    }
                    &-text {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                }
            }
            &-user {
                &-link {
                    &:hover {
                        .username {
                            color: mat.get-color-from-palette($foreground, text);
                        }
                    }
                    .username {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                }
            }
        }
    }
    .toolbar-wrap {
        &--single {
            .toolbar-global {
                .toolbar {
                    box-shadow: 0 3px 20px rgb(0 0 0 / 5%);
                }
            }
        }
    }
    .toolbar-agent-info {
        .pal-item {
            &:not(:first-of-type) {
                &:before {
                    border-top-color: mat.get-color-from-palette($foreground, divider);
                }
            }
        }
    }
}
