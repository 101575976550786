@use '@angular/material' as mat;
@mixin saved-your-search($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .rpc-saved-your-search {
        &-header {
            background-color: mat.get-color-from-palette($background, card);
            &:before {
                background-color: mat.get-color-from-palette($foreground, divider);
            }
            &-note {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
    }
}