@use '@angular/material' as mat;
@mixin base-listings($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .listings {
        &-top {
            &:after {
                background-color: rgb(0 0 0 / 54%);
            }
        }
        &-title {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
        &-sided {
            &-btn {
                .rpc-btn-link {
                    background: mat.get-color-from-palette($background, card);
                    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0);
                    &.active {
                        box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
                        .rpc-btn-link-icon {
                            color: mat.get-color-from-palette($foreground, disabled);
                        }
                    }
                }
            }
            &-map {
                &:before {
                    background-color: mat.get-color-from-palette($accent, default);
                }
            }
            .as-split-gutter {
                background-color: rgba(0,0,0,0);
            }
        }
        &-empty {
            &-icon {
                color: mat.get-color-from-palette($foreground, divider);
            }
        }
    }
}
