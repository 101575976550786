@mixin gallery-listing-card-common() {
    &.is-mobile {
        .main-gallery-card {
            &-media {
                .slider-btn {
                    pointer-events: all !important;
                    opacity: 1 !important;
                }
            }
        }
    }

    .gallery-card {
        &-host {
            &--timeline {
                display: block;
                position: relative;
                .main-gallery-card {
                    height: 100%;
                    &-media {
                        height: 160px;
                        &-remove {
                            display: none;
                        }
                        &-map {
                            position: absolute;
                            bottom: 8px;
                            right: 88px;
                            z-index: 5;
                        }
                    }
                    &-footer {
                        display: none;
                    }
                }
            }
        }
    }
    .map-view-wrap {
        .main-gallery-card {
            &-media {
                height: 170px;
            }
            &-body {
                padding: 10px;
            }
        }
    }
    .main-gallery-card {
        &--removed {
            .main-gallery-card-media {
                .slider-wrapper {
                    opacity: .5;
                }
            }
        }
        &-media {
            &:hover {
                .slider-btn {
                    pointer-events: all;
                    opacity: 1;
                }
            }
            &-btns-item {
                pointer-events: all;
                .rpc-icon-btn {
                    width: 32px;
                    line-height: 32px;
                    height: 32px;
                    border-radius: 6px;
                    border-width: 1px;
                    border-style: solid;
                    transition:
                        background-color 0.5s cubic-bezier(0.35, 0, 0.25, 1),
                        border-color 0.5s cubic-bezier(0.35, 0, 0.25, 1);
                    will-change: background-color, border-color;
                    &.active {
                        &:after {
                            position: absolute;
                            display: block;
                            content: '';
                            top: 4px;
                            right: 4px;
                            font-size: 10px;
                            width: 1em;
                            height: 1em;
                            border-radius: 50%;
                            border-width: 2px;
                            border-style: solid;
                            z-index: 5;
                            pointer-events: none;
                            transition:
                                background-color 0.5s cubic-bezier(0.35, 0, 0.25, 1),
                                border-color 0.5s cubic-bezier(0.35, 0, 0.25, 1);
                            will-change: background-color, border-color;
                        }
                    }
                    &-icon {
                        font-size: 20px;
                    }
                }
            }
            .slider {
                &-item {
                    cursor: pointer;
                }
                &-btn {
                    opacity: 0;
                    pointer-events: none;
                    transition: opacity .5s cubic-bezier(0.35, 0, 0.25, 1);
                }
            }
            &-select {
                .mat-checkbox-frame {
                    border: none;
                }
            }
        }
        &-top {
            .main-gallery-card-notes {
                .rpc-btn-link {
                    line-height: 30px;
                    padding: 0;
                    min-width: 32px;
                    height: 32px;
                    .rpc-btn-link{
                        &-icon {
                            font-size: 20px;
                        }
                        &-text {
                            display: none;
                        }
                    }
                }
            }
        }
        &-price {
            .price {
                &-wrap {
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                }
                &-simple {
                    font-size: 24px;
                    line-height: 32px;
                    font-weight: 500;
                }
            }
        }
        &-info {
            .rpc-icon-field {
                &-text {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                }
            }
        }
        &-footer {
            &-pick--empty {
                .rpc-btn-link {
                    text-align: left;
                    .mat-button-wrapper {
                        justify-content: flex-start;
                    }
                }
            }
            &-communication {
                .rpc-menu-icon-btn {
                    .badge-count {
                        right: 10px;
                        z-index: 5;
                    }
                }
            }
            .activities-wrap {
                &--removed {
                    display: grid;
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                    column-gap: 4px;
                }
            }
        }
    }
}