@mixin additional-profile-common() {
    &:not(.hybrid-mode) {
        .profile-wrap {
            &.profile-wrap--additional {
                @include media-breakpoint("gt-md") {
                    grid-template-rows: auto minmax(0, 1fr);
                }
                .profile {
                    &-body {
                        @include media-breakpoint("gt-md") {
                            grid-template-areas:
                                "header"
                                "main";
                            grid-template-rows: auto minmax(0, 1fr);
                        }
                    }
                    &-header {
                        @include media-breakpoint("gt-md") {
                            display: grid;
                        }
                    }
                    &-title {
                        @include media-breakpoint("gt-md") {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
