@use '@angular/material' as mat;
@mixin landing-toolbar($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .landing-toolbar {
        &-logo {
            .rpc-logo {
                &-name-wrap,
                &-company-wrap {
                    color: rgb(255 255 255 / 100%);
                }
            }
        }
        &-avatar {
            .avatar-initials-wrap {
                .text {
                    color: rgb(0 0 0 / 87%);
                    background-color: #fff;
                }
            }
        }
    }
}