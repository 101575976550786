@use '@angular/material' as mat;
@mixin smart-banner-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-smart {
        background-color: mat.get-color-from-palette($background, card);
        border-bottom-color: mat.get-color-from-palette($foreground, divider);
        &-close {
            &:hover {
                background-color: mat.get-color-from-palette($background, background);
            }
            &-icon {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
        &-title {
            color: mat.get-color-from-palette($foreground, text);
        }
    }
}