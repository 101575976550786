@use '@angular/material' as mat;
@mixin on-market-toolbar($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .on-market-toolbar {
        background-color: mat.get-color-from-palette($background, hover);
        &-title {
            color: #fff;
        }
        &-back,
        &-btn {
            .rpc-icon-btn {
                &:not(.mat-button-disabled) {
                    color: #fff;
                    &:hover {
                        .rpc-icon-btn-icon {
                            color: #fff;
                        }
                    }
                    .rpc-icon-btn-icon {
                        color: #fff;
                    }
                }
                &.mat-button-disabled {
                    color: #fff;
                    .rpc-icon-btn-icon {
                        color: rgb(255 255 255 / 60%) !important;
                    }
                }
            }
        }
    }
}