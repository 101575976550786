@use '@angular/material' as mat;
@mixin timeline-view($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .apnt {
        &-t {
            &-list {
                &--divider {
                    border-color: mat.get-color-from-palette($foreground, divider);
                    &-current {
                        border-color: mat.get-color-from-palette($accent, default);
                    }
                }
            }
            &-date {
                &--active {
                    .apnt-t-date {
                        &-day,
                        &-year {
                            color: mat.get-color-from-palette($foreground, text);
                        }
                    }
                }
                &--current {
                    .apnt-t-date-number {
                        color: mat.get-color-from-palette($accent, default-contrast);
                        &-wrap {
                            background-color: mat.get-color-from-palette($accent, default);
                        }
                    }
                }
                &-number {
                    color: mat.get-color-from-palette($foreground, text);
                    &-wrap {
                        @if ($is-dark) {
                            background-color: rgb(0 0 0 / 40%);
                        } @else {
                            background-color: rgb(0 0 0 / 5%);
                        }
                    }
                }
                &-day,
                &-year {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                    @include media-breakpoint("gt-xs") {
                        color: mat.get-color-from-palette($foreground, text);
                    }
                }
            }
            &-card {
                &-empty {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                }
            }
        }
    }
}
