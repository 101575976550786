@mixin on-market-common() {
    .listings-wrap {
        &.listings-wrap--search {
            .listings-empty {
                &.listings-empty--clean {
                    .listings-empty {
                        &-btn {
                            .rpc-btn-link-text {
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }
    }
}