@mixin folder-toolbar-common() {
    .folder-toolbar {
        &-back,
        &-btn {
            .rpc-icon-btn {
                border-radius: 4px;
            }
        }
    }
}
