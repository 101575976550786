@mixin folder-management-common() {
    .fm {
        max-width: 100% !important;
        width: 100%;
        @include media-breakpoint("gt-xs") {
            max-width: 80% !important;
            width: auto;
        }
        .mat-dialog-container {
            border-radius: 10px;
            padding: 0;
            max-height: calc(var(--vh) * 0.85);
            @include media-breakpoint("gt-sm") {
                overflow: hidden;
                -webkit-overflow-scrolling: initial;
            }
        }
    }
    .folder-edit-wrap {
        &.mat-menu-panel {
            width: 280px;
        }
    }
}