@mixin landing-common() {
    &:not(.hybrid-mode) {
        .landing-page {
            &-header {
                @include media-breakpoint("gt-md") {
                    grid-template-rows: repeat(2, auto);
                    align-content: end;
                    padding-top: 20px;
                    padding-bottom: 96px;
                    height: 480px;
                }
                &:after {
                    @include media-breakpoint("gt-md") {
                        display: block;
                    }
                }
                &:before,
                &-toolbar {
                    @include media-breakpoint("gt-md") {
                        display: none; 
                    }
                }
                &-block {
                    @include media-breakpoint("gt-md") {
                        padding-bottom: 0;
                    }
                }
            }
            &-form {
                &-wrap {
                    @include media-breakpoint("gt-md") {
                        position: sticky;
                        top: 0;
                        left: 0;
                        right: 0;
                        transform: translateY(-32px);
                    }
                    &.landing-page-form-wrap--sticky {
                        @include media-breakpoint("gt-md") {
                            transform: translateY(0);
                        }
                    }
                }
                &-component {
                    @include media-breakpoint("gt-md") {
                        
                    }
                }
            }
            &-body {
                @include media-breakpoint("gt-md") {
                    row-gap: 80px;
                    padding-top: 34px;
                    padding-bottom: 80px;
                    margin-top: 0;
                }
            }
            &-bottom-app {
                display: block;
                html[data-device*='ios'] & {
                    display: none;
                    @include media-breakpoint("gt-md") {
                        display: block;
                    }
                }
                html[data-device*='android'] & {
                    display: none;
                    @include media-breakpoint("gt-md") {
                        display: block;
                    }
                }
            }
        }
    }
}