@mixin activity-button-common() {
    .rpc-activity--tooltip {
        &.tooltip-list {
            padding-left: 0;
            padding-right: 0;
            width: auto;
        }
    }
    .rpc-activity-button {
        .rpc-icon-btn {
            &.rpc-icon-btn--custom {
                width: 100%;
                min-width: 1px;
                border-radius: 6px;
            }
        }
    }
}