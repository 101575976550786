@mixin edit-saved-search-dialog-common() {
    .saved-search-edit {
        &-modal {
            width: 100%;
            max-width: 100% !important;
            @include media-breakpoint("gt-xs") {
                width: 480px;
            }
            .mat-dialog-container {
                border-radius: 0px;
                padding: 0;
                height: var(--vh);
                overflow: hidden;
                @include media-breakpoint("gt-xs") {
                    border-radius: 10px;
                    width: 480px;
                    max-height: calc(var(--vh) * 0.85);
                    height: auto;
                }
            }
        }
        &-header {
            &-submit {
                .rpc-btn-link-text {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 500;
                }
            }
        }
        &-footer {
            &-item {
                &--delete {
                    .rpc-btn-link {
                        width: 100%;
                    }
                }
            }
        }
    }
}
