@use '@angular/material' as mat;
@mixin new-match-settings-page($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .nm-profile-page {
        background-color: mat.get-color-from-palette($background, card);
        &-toolbar {
            background-color: mat.get-color-from-palette($background, hover);
            &:after {
                background-color: rgb(0 0 0 / 54%);
            }
            &-title {
                color: #fff;
            }
            &-back {
                .rpc-icon-btn {
                    &:not(.mat-button-disabled) {
                        color: #fff;
                        &:hover {
                            .rpc-icon-btn-icon {
                                color: #fff;
                            }
                        }
                        .rpc-icon-btn-icon {
                            color: #fff;
                        }
                    }
                    &.mat-button-disabled {
                        color: #fff;
                        .rpc-icon-btn-icon {
                            color: rgb(255 255 255 / 60%) !important;
                        }
                    }
                }
            }
        }
    }
}