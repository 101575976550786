@use '@angular/material' as mat;
@mixin edit-saved-search-dialog($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .saved-search-edit {
        &-header {
            background-color: mat.get-color-from-palette($background, card);
            border-color: mat.get-color-from-palette($foreground, divider);
        }
        &-footer {
            &-item {
                &--delete {
                    .rpc-btn-link {
                        &:not(.mat-button-disabled) {
                            &.mat-warn {
                                .rpc-btn-link-icon {
                                    color: mat.get-color-from-palette($warn, default);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}