@use '@angular/material' as mat;
@mixin toolbar-filter-base($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-filter-menu-mobile {
        &-header {
            background-color: mat.get-color-from-palette($background, card);
        }
    }
    .filter {
        &-item {
            &.filter {
                &--more {
                    .rpc-menu-btn-link {
                        &.active {
                            color: mat.get-color-from-palette($accent, default-contrast);
                            background: mat.get-color-from-palette($accent, default);
                            border-color: mat.get-color-from-palette($accent, default);
                            .rpc-menu-btn-link {
                                &-text {
                                    color: mat.get-color-from-palette($accent, default-contrast);
                                }
                                &-icon {
                                    color: mat.get-color-from-palette($accent, default-contrast);
                                }
                                &-trigger {
                                    color: mat.get-color-from-palette($accent, default-contrast);
                                }
                            }
                        }
                        &-icon {
                            color: mat.get-color-from-palette($foreground, secondary-text);
                        }
                    }
                }
                .rpc-btn-link {
                    &.active {
                        color: mat.get-color-from-palette($accent, default-contrast);
                        background: mat.get-color-from-palette($accent, default);
                        border-color: mat.get-color-from-palette($accent, default);
                        .rpc-btn-link {
                            &-text {
                                color: mat.get-color-from-palette($accent, default-contrast);
                            }
                            &-icon {
                                color: mat.get-color-from-palette($accent, default-contrast);
                                --main-color: #{mat.get-color-from-palette($accent, default-contrast)};
                                --text-color: #{mat.get-color-from-palette($accent, default)};
                            }
                        }
                    }
                }
                .rpc-menu-btn-link {
                    &.active {
                        color: mat.get-color-from-palette($accent, default-contrast);
                        background: mat.get-color-from-palette($accent, default);
                        border-color: mat.get-color-from-palette($accent, default);
                        .rpc-menu-btn-link {
                            &-icon {
                                color: mat.get-color-from-palette($accent, default-contrast);
                                --main-color: #{mat.get-color-from-palette($accent, default-contrast)};
                                --text-color: #{mat.get-color-from-palette($accent, default)};
                            }
                            &-text {
                                color: mat.get-color-from-palette($accent, default-contrast);
                            }
                            &-trigger {
                                color: mat.get-color-from-palette($accent, default-contrast);
                            }
                        }
                    }
                }
            }
            &.pick {
                .rpc-btn-link {
                    &-icon {
                        color: var(--picklist-color);
                    }
                }
            }
            &.like {
                .rpc-btn-link {
                    &-icon {
                        color: var(--like-color);
                    }
                }
            }
            &.show {
                .rpc-btn-link {
                    &-icon {
                        color: var(--visit-color);
                    }
                }
            }
            &.dislike {
                .rpc-btn-link {
                    &-icon {
                        color: var(--dislike-color);
                    }
                }
            }
            &.nm {
                .rpc-btn-link {
                    &-icon {
                        color: var(--nm-500);
                    }
                }
            }
            &.na {
                .rpc-btn-link {
                    &-icon {
                        color: var(--na-500);
                        --main-color: var(--na-500);
                        --text-color: var(--na-200);
                    }
                }
            }
            &.past {
                .rpc-btn-link {
                    &-icon {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                }
            }
            &.declined {
                .rpc-btn-link {
                    &-icon {
                        color: var(--status-declined);
                        --main-color: var(--status-declined);
                        --text-color: #fff;
                    }
                }
            }
            .rpc-btn-link,
            .rpc-menu-btn-link {
                background: mat.get-color-from-palette($background, card);
            }
        }
        &-menu {
            &-title {
                &-icon {
                    color: mat.get-color-from-palette($accent, default);
                }
            }
            &-item {
                border-bottom-color: mat.get-color-from-palette($foreground, divider);
                &--btns-border {
                    border-top-color: mat.get-color-from-palette($foreground, divider);
                }
                &-title {
                    color: mat.get-color-from-palette($foreground, text);
                    &.filter-menu-item-title {
                        &--with-icon {
                            .filter-menu-item-title-icon {
                                color: mat.get-color-from-palette($accent, default);
                            }
                        }
                        &-user {
                            .rpc-menu-btn-link-icon {
                                color: mat.get-color-from-palette($accent, default);
                            }
                        }
                    }
                    &-counter {
                        color: mat.get-color-from-palette($accent, default-contrast);
                        background-color: mat.get-color-from-palette($accent, default);
                    }
                }
            }
        }
        &-dropdown {
            &:not(mat-menu) {
                border-color: mat.get-color-from-palette($foreground, divider);
            }
            &--mobile {
                .filter {
                    &-menu {
                        &-box {
                            @if ($is-dark) {
                                background: mat.get-color-from-palette($background, card);
                            } @else {
                                background: mat.get-color-from-palette($background, card);
                            }
                        }
                        &-item {
                            &--clear {
                                .rpc-btn-link {
                                    background-color: mat.get-color-from-palette($background, card);
                                }
                            }
                        }
                        &-btn {
                            .rpc-btn-link {
                                background-color: mat.get-color-from-palette($background, card);
                            }
                        }
                    }
                }
            }
        }
    }
}
