@mixin new-version-dialog-common() {
    .rpc-new-version-modal {
        max-width: 100% !important;
        width: 100%;
        .mat-dialog-container {
            border-radius: 12px;
            padding: 0;
            width: calc(100% - 32px);
            max-width: 400px;
            max-height: calc(var(--vh) * 0.9);
            margin: 0 auto;
        }
    }
    .rpc-new-version {
        .rpc-new-version-btn {
            .rpc-btn-link {
                line-height: 44px;
                width: 100%;
                height: 44px;
                &-text {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 500;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                }
            }
        }
    }
}