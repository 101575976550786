@mixin change-agent-dialog-common() {
    .ca {
        &-dialog {
            position: absolute !important;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            max-width: none !important;
            @include media-breakpoint("gt-xs") {
                position: static !important;
                max-width: 100% !important;
            }
            .mat-dialog-container {
                border-radius: 0px;
                padding: 0;
                width: 600px;
                max-width: 100%;
                @include media-breakpoint("gt-xs") {
                    border-radius: 10px;
                    height: auto;
                    max-height: calc(var(--vh) * 0.85);
                    overflow: hidden;
                }
            }
        }
        &-header-action {
            .rpc-btn-link-text {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
            }
        }
        &-list {
            .agent-selection {
                &-inner {
                    display: grid;
                    grid-template-columns: 100%;
                    row-gap: 24px;
                    align-items: start;
                }
                &-title,
                &-description {
                    display: none;
                }
                &-list {
                    display: grid;
                    grid-template-columns: 100%;
                    row-gap: 16px;
                    align-items: start;
                    max-height: auto;
                    overflow: visible;
                    margin: 0;
                    &-item {
                        margin: 0;
                    }
                }
                &-action-wrap {
                    display: none;
                }
            }
        }
    }
}