@mixin registration-common() {
    &:not(.hybrid-mode) {
        .registration-page {
            &-wrap {
                @include media-breakpoint("gt-md") {
                    grid-template-rows: 100%;
                }
            }
            &-toolbar {
                @include media-breakpoint("gt-md") {
                    display: none;
                }
            }
        }
    }
    .register {
        .auth {
            &-form {
                &-input {
                    &:last-of-type {
                        .mat-form-field-wrapper {
                            padding-bottom: 10px;
                        }
                    }
                }
                .agent-name {
                    font-size: 16px;
                }
            }
        }
    }
}
