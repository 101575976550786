@use '@angular/material' as mat;
@mixin profile-settings($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .profile-settings {
        &-section {
            &-block {
                background-color: mat.get-color-from-palette($background, card);
                border-color: mat.get-color-from-palette($foreground, divider);
                .theme-toggle {
                    &-note {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                }
            }
            &-box {
                &:not(:last-of-type) {
                    border-bottom-color: mat.get-color-from-palette($foreground, divider); 
                }
            }
        }
    }
}