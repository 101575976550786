@mixin timeline-view-common() {
    .apnt-t-card {
        &-box {
            &--with-map {
                .main-gallery-card {
                    @include media-breakpoint("gt-sm") {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                    &-media {
                        @include media-breakpoint("gt-sm") {
                            border-top-right-radius: 0;
                        }
                    }
                }
            }
            &-map {
                &-btn {
                    .rpc-icon-btn {
                        &-icon {
                            font-size: 34px;
                        }
                    }
                }
            }
        }
        .main-gallery-card {
            height: 100%;
            &-body {
                padding: 10px;
            }
        }
    }
}
