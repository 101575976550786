@mixin profile-common() {
    &.hybrid-mode {
        .profile {
            &-wrap {
                .rpc-scroll-to-top-wrap {
                    bottom: 65px;
                }
            }
        }
    }
    &:not(.hybrid-mode) {
        .profile {
            &-wrap {
                @include media-breakpoint("gt-md") {
                    grid-template-rows: 0px minmax(0, 1fr);
                }
                .profile {
                    &-agent {
                        &.profile-agent--multiple {
                            @include media-breakpoint("gt-md") {
                                grid-template-columns: 100%;
                                row-gap: 0;
                                border-width: 1px;
                            }
                            .pal-item {
                                @include media-breakpoint("gt-md") {
                                    border-width: 0;
                                    border-radius: 0;
                                }
                                &:not(:last-of-type) {
                                    @include media-breakpoint("gt-md") {
                                        border-bottom-width: 1px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &-agent {
                &-title {
                    @include media-breakpoint("gt-md") {
                        display: block;
                    }
                }
            }
            &-sidebar {
                @include media-breakpoint("gt-md") {
                    row-gap: 24px;
                }
            }
            &-body {
                @include media-breakpoint("gt-md") {
                    grid-template-areas:
                        "title"
                        "main";
                    grid-template-rows: auto minmax(0, 1fr);
                    row-gap: 24px;
                    padding: 24px 70px 10px;
                    max-width: 1340px;
                    margin: 0 auto;
                }
            }
            &-toolbar-wrap,
            &-mobile,
            &-powered {
                @include media-breakpoint("gt-md") {
                    display: none;
                }
            }
            &-title,
            &-content {
                @include media-breakpoint("gt-md") {
                    display: block;
                }
            }
            &-box {
                @include media-breakpoint("gt-md") {
                    grid-template-columns: 320px minmax(0, 1fr);
                    grid-template-areas: "side desctop";
                }
            }
        }
    }
    .profile {
        &-wrap {
            .profile {
                &-customer {
                    &-item--name {
                        padding: 0;
                        .item-btn {
                            display: none;
                        }
                    }
                }
                &-agent {
                    &.profile-agent--multiple {
                        .pal {
                            &-item {
                                display: block;
                                padding: 16px;
                                @media screen and (min-width: 834px) {
                                    border-radius: 10px;
                                    border-width: 1px;
                                    border-style: solid;
                                }
                                &:not(:last-of-type) {
                                    border-bottom-width: 1px;
                                    border-bottom-style: solid;
                                }
                            }
                        }
                    }
                    .pal {
                        &-item {
                            display: block;
                            padding: 16px;
                            &:not(:last-of-type) {
                                border-bottom: 1px;
                                border-bottom-style: solid;
                            }
                        }
                        &-divider {
                            display: none;
                        }
                    }
                }
                &-content {
                    &.mat-tab-group {
                        .mat-tab-body-content {
                            padding: 24px;
                        }
                    }
                }
            }
        }
    }
}
