@use '@angular/material' as mat;
@mixin saved-search-list($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .rpc-saved-search-list {
        &-header {
            background-color: mat.get-color-from-palette($background, card);
            &:before {
                background-color: mat.get-color-from-palette($foreground, divider);
            }
        }
        &-add {
            background-color: mat.get-color-from-palette($background, background);
            border-color: mat.get-color-from-palette($foreground, divider);
            &:hover {
                border-color: mat.get-color-from-palette($accent, default);
            }
            &-icon {
                color: mat.get-color-from-palette($accent, default);
            }
            &-trigger {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
    }
}