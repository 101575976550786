@mixin restore-password-common() {
    &:not(.hybrid-mode) {
        .restore-password-page {
            &-wrap {
                @include media-breakpoint("gt-md") {
                    grid-template-rows: 100%;
                }
            }
            &-toolbar {
                @include media-breakpoint("gt-md") {
                    display: none;
                }
            }
        }
    }
    .restore-password {
        .auth {
            &-submit-button {
                .rpc-btn-link {
                    @include media-breakpoint("gt-sm") {
                        width: auto;
                    }
                }
            }
        }
    }
}
