@mixin onboarding-dialog-common() {
    .rpc-onboarding {
        &-modal {
            position: relative !important;
            width: 640px;
            max-width: 100% !important;
            max-height: calc(var(--vh) * 0.8);
            @include media-breakpoint("gt-xs") {
                max-width: 80% !important;
            }
            .mat-dialog-container {
                padding: 40px 16px 16px;
                @include media-breakpoint("gt-sm") {
                    padding: 32px 40px;
                }
            }
        }
        .rpc-onboarding {
            &-close {
                .rpc-btn-link {
                    padding: 0;
                    min-width: 40px;
                    &-text {
                        display: none;
                    }
                }
            }
            &-image {
                .default-image-wrap {
                    .main-background-image {
                        background-size: contain;
                    }
                }
            }
            &-nav {
                .rpc-icon-btn {
                    border-radius: 6px;
                    border-width: 1px;
                    border-style: solid;
                }
            }
        }
    }
}
