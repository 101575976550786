@mixin profile-edit-email-list-common() {
    .profile-edit-email {
        .profile-edit-email {
            &-item {
                &-toggle {
                    .mat-slide-toggle {
                        &-label {
                            align-items: flex-start;
                        }
                        &-bar {
                            margin-top: 5px;
                            margin-right: 12px;
                        }
                    }
                }
            }
        }
    }
}
