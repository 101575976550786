@mixin edit-profile-info-common() {
    &:not(.hybrid-mode) {
        .edit-customer-info {
            @include media-breakpoint("gt-md") {
                grid-template-rows: minmax(0, 1fr) auto;
            }
            .edit-customer-info {
                &-header {
                    @include media-breakpoint("gt-md") {
                        display: none;
                    }
                }
                &-body,
                &-footer {
                    @include media-breakpoint("gt-md") {
                        padding: 0;
                    }
                }
            }
        }
    }

    .edit-customer-info {
        &-modal {
            position: absolute !important;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            max-width: none !important;
            @include media-breakpoint("gt-xs") {
                position: static !important;
                max-width: 100% !important;
            }
            .mat-dialog-container {
                padding: 0;
                @include media-breakpoint("gt-xs") {
                    border-radius: 10px;
                    max-height: calc(var(--vh) * 0.85);
                    max-width: 100%;
                    width: 594px;
                }
            }
        }
        .edit-customer-info {
            &-header-action {
                .rpc-btn-link-text {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 500;
                }
            }
        }
    }
}