@mixin saved-search-form-common() {
    .saved-search {
        &-preset {
            &-input-wrap {
                .rpc-input {
                    .mat-form-field {
                        &-wrapper {
                            padding: 0;
                        }
                        &-subscript-wrapper {
                            .mat-error {
                                white-space: normal;
                            }
                        }
                        &-infix {
                            width: 100%;
                        }
                    }
                }
            }
            &-select-wrap {
                .rpc-select {
                    .mat-form-field {
                        &-wrapper {
                            padding: 0;
                        }
                        &-subscript-wrapper {
                            .mat-error {
                                white-space: normal;
                            }
                        }
                        &-infix {
                            width: 100%;
                        }
                    }
                }
            }
            &-folder {
                display: block;
                .rpc-btn-link {
                    display: block;
                    width: 100%;
                    height: 48px;
                    line-height: 48px;
                    &-icon {
                        font-size: 24px;
                    }
                    &-text {
                        font-size: 16px;
                        line-height: 20px;
                    }
                    .mat-button-wrapper {
                        justify-content: flex-start;
                    }
                }
                &-form {
                    .folder-edit {
                        &-form {
                            display: grid;
                            grid-template-columns: 100%;
                            row-gap: 20px;
                            align-items: start;
                            padding: 20px;
                            .mat-form-field {
                                &-wrapper {
                                    padding-bottom: 0;
                                    margin-bottom: 0;
                                }
                                &-subscript-wrapper {
                                    margin: 0;
                                }
                                .mat-error { 
                                    margin-top: 5px;
                                }
                            }
                        }
                        &-action {
                            &-wrap {
                                column-gap: 10px;
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
            &-toggle {
                .mat-slide-toggle-content {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                }
            }
        }
        &-mobile-options-wrap {
            &.mat-bottom-sheet-container {
                display: block;
                border-radius: 10px 10px 0 0;
                padding: 0;
                max-height: calc(var(--vh) * 0.85);
                overflow: hidden;
            }
        }
        &-option-wrap {
            .saved-search-option {
                &-header {
                    .saved-search-preset-folder {
                        .rpc-btn-link {
                            border-radius: 0;
                        }
                    }
                }
                &-list {
                    .saved-search-option-folder {
                        .rpc-btn-link {
                            display: block;
                            border-radius: 0;
                            width: 100%;
                            height: 48px;
                            line-height: 48px;
                            &-icon {
                                font-size: 24px;
                            }
                            &-text {
                                font-size: 16px;
                                line-height: 20px;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                            }
                            .mat-button-wrapper {
                                justify-content: flex-start;
                            }
                        }
                    }
                }
            }
        }
    }
}
