@use '@angular/material' as mat;
@mixin rpc-checkbox($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-checkbox {
        &.mat-checkbox {
            &-disabled {
                &:not(.mat-checkbox-checked) {
                    .mat-checkbox-frame {
                        border-color: rgb(0 0 0 / 0%);
                    }
                }
            }
        }
        .mat-checkbox {
            &-background {
                @if ($is-dark) {
                    background-color: rgb(255 255 255 / 16%);
                } @else {
                    background-color: rgb(0 0 0 / 12%);
                }
            }
            &-frame {
                border-color: rgb(0 0 0 / 0%);
            }
        }
    }
}