@mixin additional-profiles-tab-common() {
    &:not(.hybrid-mode) {
        .aditional-profiles-list {
            &-title {
                &-wrap {
                    @include media-breakpoint("gt-md") {
                        grid-template-columns: minmax(0, 1fr) auto;
                        grid-template-areas: "title btn";
                    }
                }
                &-btn {
                    @include media-breakpoint("gt-md") {
                        display: block;
                    }
                }
            }
        }
    }
    .aditional-profiles-list {
        &-item-right {
            &.aditional-profiles-list-item-right--active {
                .rpc-btn-link {
                    border-radius: 0;
                    @media screen and (min-width: 834px) {
                        border-radius: 4px;
                    }
                    .mat-button-wrapper {
                        justify-content: flex-start;
                    }
                }
            }
            .rpc-btn-link {
                display: block;
                width: 100%;
                @media screen and (min-width: 834px) {
                    border-radius: 4px;
                }
            }
        }
    }
}