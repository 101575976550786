@mixin my-appointments-common() {
    &.calendar-view-wrap {
        .global-content {
            display: block;
            overflow: visible;
        }
        my-appointments {
            display: block;
            height: 100%;
        }
    }
    &:not(.hybrid-mode) {
        .apnt {
            &-wrap {
                @include media-breakpoint("gt-md") {
                    grid-template-rows: auto minmax(0, 1fr);
                }
            }
            &-toolbar {
                @include media-breakpoint("gt-md") {
                    display: none;
                }
            }
        }
    }
}
