@use '@angular/material' as mat;
@mixin landing-search-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    &:not(.hybrid-mode) {
        .landing-page-search {
            &.landing-page-search--sticky {
                @include media-breakpoint("gt-md") {
                    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0);
                }
            }
        }
    }
    .landing-page-search {
        background-color: mat.get-color-from-palette($background, card);
        box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, .06);
        &-filter {
            .rpc-btn-link,
            .rpc-menu-btn-link {
                background: mat.get-color-from-palette($background, card);
            }
        }
    }
}