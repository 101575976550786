html {
    &[data-device*=ios],
    &[data-device*=android] {
        top: 0 !important;
    }
}
html,
body {
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: visible !important;
    overscroll-behavior: none;
}
html[data-device*=android],
html[data-device*=ios] {
    top: 0 !important;
    body {
        &.modal-open {
            .global-content {
                overflow: hidden;
            }
        }
    }
}
html[data-device*=android] {
    clip-path: inset(0 0 0 0);
    &.rpc-ld-page-wrap {
        overflow: visible !important;
        body {
            // overflow: hidden !important;
            clip-path: inset(0 0 0 0);
        }
    }
    &.cdk-global-scrollblock {
        position: fixed !important;
        overflow: visible !important;
        body {
            // overflow: hidden !important;
            clip-path: inset(0 0 0 0);
            .global-content {
                overflow: hidden;
            }
        }
    }
}

// Remove outline for Firefox
.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button {
    &::-moz-focus-inner,
    &:active,
    &:focus {
        outline: none;
        -moz-outline-style: none;
    }
}
body {
    &.refreshing {
        .global-toolbar,
        .global,
        .quick-panel {
            filter: blur(1px);
            touch-action: none;
            pointer-events: none;
        }
        .refresher {
            transform: translate3d(0, 150%, 0) scale(1);
            z-index: 1100;
            visibility: visible;
            &.shrink {
                transform: translate3d(0, 150%, 0) scale(0);
                opacity: 0;
            }
        }
        .calendar-legend-btn {
            [data-device*="android"] & {
                @include media-breakpoint("xs") {
                    top: 93px;
                }
            }
        }
        .sr-view-sidebar {
            @include media-breakpoint("sm") {
                top: 0;
            }
            @include media-breakpoint("xs") {
                top: 0;
            }
        }
    }
    // .cdk-overlay-container {
    //     position: absolute;
    // }
}
button,
input[type="email"],
input[type="tel"],
input[type="text"],
input[type="password"],
input[type="image"],
input[type="submit"],
input[type="button"],
input[type="search"],
textarea {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: none;
}
.word-wrap {
    word-break: break-all;
}
.update-toast a {
    color: #ffffff !important;
}
