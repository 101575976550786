@mixin invite-user-dialog-common() {
    &:not(.hybrid-mode) {
        .invite-child {
            @include media-breakpoint("gt-md") {
                grid-template-rows: auto minmax(0, 1fr) auto;
            }
        }
    }
    .invite-child {
        &-modal {
            position: absolute !important;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            max-width: none !important;
            @include media-breakpoint("gt-xs") {
                position: static !important;
                max-width: 100% !important;
            }
            .mat-dialog-container {
                border-radius: 0;
                padding: 0;
                @include media-breakpoint("gt-xs") {
                    border-radius: 10px;
                    max-height: calc(var(--vh) * 0.85);
                    max-width: 100%;
                    width: 594px;
                }
            }
        }
        &-header-action {
            .rpc-btn-link-text {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
            }
        }
    }
}