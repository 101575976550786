@use '@angular/material' as mat;
@mixin landing-page($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    &:not(.hybrid-mode) {
        .landing-page {
            &-header{
                &:after {
                    @include media-breakpoint("gt-md") {
                        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 23%, rgba(0, 0, 0, .6) 68%);
                    }
                }
            }
            &-form {
                &-wrap {
                    &.landing-page-form-wrap--sticky {
                        @include media-breakpoint("gt-md") {
                            background-color: mat.get-color-from-palette($background, card);
                            box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, .06);
                        }
                        .landing-page-form-component {
                            @include media-breakpoint("gt-md") {
                                background-color: rgba(0,0,0,0);
                            }
                        }
                    }
                }
            }
        }
    }

    .landing-page {
        &-header {
            &:before {
                background-color: rgb(0 0 0 / 54%);
            }
            &-title {
                color: rgba(255,255,255,1);
            }
        }
        &-bottom-app {
            background-color: rgb(0 0 0 / 5%);
            border-top-color: mat.get-color-from-palette($foreground, divider);
        }
    }
}