@mixin on-market-secondary-toolbar-common() {
    &:not(.hybrid-mode) {
        .on-market-secondary-toolbar {
            &.on-market-secondary-toolbar {
                &--full {
                    @include media-breakpoint("gt-md") {
                        border-radius: 0;
                        overflow: visible;
                    }
                }
                &--top {
                    .on-market-secondary-toolbar {
                        &-content {
                            @include media-breakpoint("gt-md") {
                                row-gap: 16px;
                                margin: 0 54px;
                            }
                        }
                        &-top,
                        &-bottom {
                            @include media-breakpoint("gt-md") {
                                border: none;
                            }
                        }
                        &-top {
                            @include media-breakpoint("gt-md") {
                                grid-template-columns: minmax(0, 1fr) min-content;
                                grid-template-areas: 
                                    "title-full title-full"
                                    "filter filter";
                                padding: 28px 16px 0;
                            }
                        }
                        &-title {
                            &--sticky {
                                @include media-breakpoint("gt-md") {
                                    display: none;
                                }
                            }
                            &--full {
                                @include media-breakpoint("gt-md") {
                                    display: block;
                                }
                            }
                        }
                        &-bottom {
                            @include media-breakpoint("gt-md") {
                                padding: 0 16px;
                            }
                        }
                    }
                }
            }
            &-top {
                @include media-breakpoint("gt-md") {
                    grid-template-columns: 100%;
                    grid-template-areas: "filter";
                    column-gap: 16px;
                    padding: 6px 24px 5px;
                }
                &--counter {
                    @include media-breakpoint("gt-md") {
                        grid-template-columns: auto minmax(0, 1fr);
                        grid-template-areas: "title-sticky filter";
                    }
                }
                &-sort {
                    @include media-breakpoint("gt-md") {
                        display: none;
                    }
                }
                &-switch {
                    @include media-breakpoint("gt-md") {
                        display: none;
                    }
                    &-mobile {
                        @include media-breakpoint("gt-md") {
                            display: none;
                        }
                    }
                    &-item {
                        @include media-breakpoint("gt-md") {
                            display: block;
                        }
                    }
                }
                &-omni-wrap {
                    @include media-breakpoint("gt-md") {
                        display: none;
                    }
                }
            }
            &-title--sticky {
                @include media-breakpoint("gt-md") {
                    display: grid;
                }
            }
            &-bottom {
                @include media-breakpoint("gt-md") {
                    grid-template-columns: minmax(0, 1fr) auto;
                    border-bottom: none;
                    padding: 6px 16px;
                }
                &-right {
                    @include media-breakpoint("gt-md") {
                        display: grid;
                    }
                }
            }
            &-select {
                &-divider {
                    @include media-breakpoint("gt-md") {
                        display: block;
                    }
                }
            }
        }
    }
    .on-market-secondary-toolbar {
        &-top {
            &-sort {
                .rpc-menu-btn-link {
                    &-text,
                    &-trigger {
                        display: none;
                    }
                }
            }
            &-omni {
                .chip-wrap {
                    width: 100%;
                    .chip-list-empty {
                        display: grid;
                        grid-template-columns: 100%;
                        align-items: center;
                        justify-content: start;
                        &-text {
                            padding: 8px 0;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }

    .saved-search {
        &-option {
            &-dropdown {
                @include media-breakpoint("gt-xs") {
                    width: 320px;
                    max-width: 320px;
                }
                .mat-menu-content {
                    padding: 20px;
                }
            }
        }
        &-panel {
            &-wrap {
                &.mat-bottom-sheet-container {
                    position: relative;
                    display: block;
                    padding: 0;
                    border-radius: 10px 10px 0 0;
                    overflow: hidden;
                    .saved-search {
                        &-panel-btn {
                            position: absolute;
                            top: 6px;
                            right: 5px;
                            z-index: 10;
                        }
                        &-option {
                            &-wrap {
                                grid-template-rows: auto minmax(0, 1fr);
                                height: 100%;
                            }
                            &-note {
                                position: sticky;
                                top: 0;
                                left: 0;
                                right: 0;
                                padding: 16px 50px 5px 10px;
                                z-index: 5;
                            }
                            &-list {
                                row-gap: 10px;
                                padding: 0 10px 20px;
                                height: 100%;
                                overflow-y: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}
