@mixin profile-top($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .customer-info {
        &-item--email {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
    }
}
