@mixin notifications-common() {
    &:not(.hybrid-mode) {
        .rpc-notifications {
            &-header {
                @include media-breakpoint("gt-md") {
                    grid-template-columns: minmax(0, 1fr) auto;
                    grid-template-areas:
                        "title clear"
                        "toogle toogle";
                }
            }
            &-close {
                @include media-breakpoint("gt-md") {
                    display: none;
                }
            }
        }
    }
    .rpc-notifications {
        &-dropdown {
            min-width: 1px;
            max-width: none;
            width: 560px;
        }
        &-bottomsheet {
            border-radius: 8px 8px 0 0;
            padding: 0;
            min-width: auto !important;
            width: 100%;
            max-width: 600px !important;
            height: calc(var(--vh) * 0.85);
            max-height: calc(var(--vh) * 0.85) !important;
            overflow: hidden;
            .rpc-notifications {
                padding-top: 0;
                overflow: hidden;
                overflow-y: auto;
                &-header {
                    padding-top: 15px;
                }
            }
        }
        &-tabs {
            &.mat-tab-group {
                .mat-tab-body-content {
                    padding: 20px 0px 0;
                }
            }
            .mat-tab {
                &-header {
                    position: -webkit-sticky;
                    position: sticky;
                    top: -1px;
                    padding-top: 5px;
                    z-index: 5;
                    @include media-breakpoint("gt-xs") {
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }
                &-label {
                    min-width: 1px;
                    height: 40px;
                    padding: 0 15px;
                    &-content {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 20px;
                        text-transform: uppercase;
                        user-select: none;
                        .badge-count {
                            top: -9px;
                            right: -13px;
                        }
                    }
                }
            }
        }
    }
}
