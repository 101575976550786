@mixin last-price-change-common() {
    .rpc-pc-short-tooltip {
        &.tooltip-list {
            padding: 0;
            min-width: 300px;
        }
    }
    .price {
        &-wrap {
            .mat-menu-content {
                padding: 0;
                font-size: 14px;
                line-height: 20px;
            }
        }
        &-btn {
            .rpc-menu-icon-btn {
                font-size: 20px !important;
                width: 30px;
                height: 30px;
                line-height: 30px;
                display: block;
            }
        }
    }
}
