@mixin gallery-listing-appointment-card-common() {
    .apnt-t-card-box {
        &.apnt-t-card-box {
            &--flip {
                &.apnt-t-card-box--with-map {
                    .card-face--front {
                        .apnt-t-appointment-update-wrap {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
