@use '@angular/material' as mat;
@mixin saved-search-info($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .saved-search-info {
        background-color: rgba(0,0,0,0);
        &:hover {
            background-color: mat.get-color-from-palette($background, background);
        }
        &.saved-search-info--active {
            background-color: mat.get-color-from-palette($accent, default);
            .saved-search-info {
                &-icon-wrap {
                    @include media-breakpoint("gt-xs") {
                        background-color: mat.get-color-from-palette($accent, default-contrast);
                    }
                }
                &-title-text,
                &-subtitle-item {
                    color: mat.get-color-from-palette($accent, default-contrast);
                }
                &-edit {
                    .rpc-icon-btn {
                        &:not(.mat-button-disabled) {
                            .rpc-icon-btn-icon {
                                color: mat.get-color-from-palette($accent, default-contrast);
                            }
                        }
                    }
                }
            }
        }
        &-icon {
            color: mat.get-color-from-palette($accent, default);
            &-wrap {
                @include media-breakpoint("gt-xs") {
                    background-color: mat.get-color-from-palette($accent, 500, .1);
                }
            }
        }
        &-title {
            &-text {
                color: mat.get-color-from-palette($foreground, text);
            }
            &-label {
                &--sale {
                    color: var(--sales-flag-text);
                    background-color: var(--sales-flag);
                }
                &--rental {
                    color: rgba(0,0,0,.87);
                    background-color: var(--rentals-flag);
                }
            }
        }
        &-subtitle-item {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
        &-edit {
            .rpc-icon-btn {
                &:not(.mat-button-disabled) {
                    &:hover {
                        .rpc-icon-btn-icon {
                            color: mat.get-color-from-palette($accent, default);
                        }
                    }
                    .rpc-icon-btn-icon {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                }
            }
        }
    }
}