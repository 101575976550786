@use '@angular/material' as mat;
@mixin price-change-info($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .price-dropdown {
        color:mat.get-color-from-palette($foreground, text);
        &-info {
            border-left-color:mat.get-color-from-palette($accent, default);
            &-option {
                span {
                    color:mat.get-color-from-palette($foreground, secondary-text);
                }
            }
        }
    }
}
