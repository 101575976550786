@use "@angular/material" as mat;
@mixin profile($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    &:not(.hybrid-mode) {
        .profile {
            &-agent {
                &.profile-agent--multiple {
                    @include media-breakpoint("gt-md") {
                        background-color: mat.get-color-from-palette($background, card);
                    }
                    .pal-item {
                        @include media-breakpoint("gt-md") {
                            background-color: rgb(0 0 0 / 0%);
                            border-color: rgb(0 0 0 / 0%);
                        }
                        &:not(:last-of-type) {
                            @include media-breakpoint("gt-md") {
                                border-bottom-color: mat.get-color-from-palette($foreground, divider);
                            }
                        }
                    }
                }
            }
        }
    }
    .profile {
        &-title {
            color: mat.get-color-from-palette($foreground, text);
        }
        &-customer {
            background-color: mat.get-color-from-palette($background, card);
            border-color: mat.get-color-from-palette($foreground, divider);
            &-bg {
                background-color: mat.get-color-from-palette($accent, default);
            }
        }
        &-agent {
            background-color: mat.get-color-from-palette($background, card);
            border-color: mat.get-color-from-palette($foreground, divider);
            &.profile-agent--multiple {
                @media screen and (min-width: 834px) {
                    background-color: rgb(0 0 0 / 0%);
                }
                .pal-item {
                    @media screen and (min-width: 834px) {
                        background-color: mat.get-color-from-palette($background, card);
                        border-color: mat.get-color-from-palette($foreground, divider);
                    }
                }
            }
            .pal-item {
                &:not(:last-of-type) {
                    border-bottom-color: mat.get-color-from-palette($foreground, divider);
                }
            }
        }
        &-mobile {
            &-action {
                &:hover {
                    .profile-mobile-action {
                        &-icon,
                        &-trigger,
                        &-text {
                            color: mat.get-color-from-palette($accent, default);
                        }
                    }
                }
                &-icon,
                &-trigger {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                }
                &-text {
                    color: mat.get-color-from-palette($foreground, text);
                }
            }
            &-link {
                &:hover {
                    .profile-mobile-link {
                        &-icon,
                        &-text {
                            color: mat.get-color-from-palette($accent, default);
                        }
                    }
                }
                &-icon {
                    color: mat.get-color-from-palette($accent, default);
                }
                &-text {
                    color: mat.get-color-from-palette($foreground, text);
                }
            }
        }
        &-content {
            background-color: mat.get-color-from-palette($background, card);
            border-color: mat.get-color-from-palette($foreground, divider);
        }
        &-powered {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
    }
}
