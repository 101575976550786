@mixin saved-search-list-common() {
    .saved-search {
        &-bottomsheet {
            &.mat-bottom-sheet-container {
                max-width: 600px;
                padding: 0;
                border-radius: 10px 10px 0 0;
                max-height: calc(var(--vh) * 0.85);
                transition: height .3s cubic-bezier(0.35, 0, 0.25, 1);
                will-change: height;
                &.rpc-bottomsheet--top {
                    height: var(--vh) !important;
                    max-height: var(--vh);
                    border-radius: 0;
                    transition: none;
                    .rpc-saved-search-list {
                        &-header {
                            padding-top: 8px;
                            &:before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        &-modal {
            width: 100%;
            max-width: 100% !important;
            @include media-breakpoint("gt-xs") {
                width: 480px;
            }
            .mat-dialog-container {
                border-radius: 10px;
                padding: 0;
                max-height: calc(var(--vh) * 0.85);
                width: 480px;
                max-width: 100%;
                height: auto;
            }
            .rpc-saved-search-list {
                &-header {
                    padding-top: 8px;
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}