@mixin app-common() {
    &:not(.hybrid-mode) {
        &.not-authorized {
            .global {
                @include media-breakpoint("gt-md") {
                    grid-template-rows: auto minmax(0, 1fr);
                }
            }
        }
        .progress-bar {
            display: block;
        }
        .global {
            @include media-breakpoint("gt-md") {
                grid-template-rows: minmax(0, 1fr);
            }
            &--smart {
                @include media-breakpoint("gt-md") {
                    grid-template-rows: minmax(0, 1fr);
                }
            }
            &--toolbar {
                @include media-breakpoint("gt-md") {
                    grid-template-rows: auto minmax(0, 1fr);
                }
                &.global--smart {
                    @include media-breakpoint("gt-md") {
                        grid-template-rows: auto minmax(0, 1fr);
                    }
                }
            }
            &-smart {
                @include media-breakpoint("gt-md") {
                    display: none;
                }
            }
            &-toolbar {
                display: none;
                @include media-breakpoint("gt-md") {
                    display: block;
                }
            }
            &-content {
                padding-bottom: 0;
                @include media-breakpoint("gt-md") {
                    padding-bottom: 32px;
                }
                &.global-content {
                    &--without-footer {
                        @include media-breakpoint("gt-md") {
                            padding-bottom: 0;
                        }
                    }
                }
            }
            &-footer {
                display: block;
                @include media-breakpoint("gt-md") {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    z-index: 100;
                }
            }
            &-bottombar {
                @include media-breakpoint("gt-md") {
                    display: none;
                }
            }
        }
    }
    &.not-authorized {
        .global {
            grid-template-rows: minmax(0, 1fr);
            &--smart {
                grid-template-rows: auto minmax(0, 1fr);
            }
        }
    }
}