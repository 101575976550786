@use '@angular/material' as mat;
@mixin app($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);
    .refresher {
        background-color: mat.get-color-from-palette($background, background);
        .loading-bar {
            background-color: mat.get-color-from-palette($accent, default);
        }
    }
}
