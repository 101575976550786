@mixin additional-profile-page-common() {
    &.hybrid-mode {
        .additional-profile-page {
            .rpc-scroll-to-top-wrap {
                bottom: 64px;
                @include media-breakpoint("gt-xs") {
                    bottom: 64px;
                }
            }
        }
    }
    .additional-profile-page-toolbar {
        &-back,
        &-btn {
            .rpc-icon-btn {
                border-radius: 4px;
            }
        }
    }
}