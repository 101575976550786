@use "@angular/material" as mat;
@mixin profile-edit-phone-list($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .profile-edit-phone {
        &-item {
            border-bottom-color: mat.get-color-from-palette($foreground, divider);
        }
    }
}