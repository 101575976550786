@use '@angular/material' as mat;
@mixin folder-details-item($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .folder {
        &-creator {
            &-info {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
        &-saved-search {
            &-title {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
            &-icon,
            &-trigger {
                color: mat.get-color-from-palette($accent, default);
            }
        }
        &-default-toggle {
            &-note {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
    }
}