@mixin remove-folder-dialog-common() {
    &:not(.hybrid-mode) { 
        .rfd-action-wrap {
            @include media-breakpoint("gt-md") {
                grid-template-columns: repeat(2, auto);
                justify-content: end;
            }
        }
    }
    .delete-folder-modal {
        max-width: 100% !important;
        @include media-breakpoint("gt-xs") {
            max-width: 80vw !important;
        }
        .mat-dialog-container {
            border-radius: 10px;
            padding: 10px 16px 16px;
            max-width: 100%;
            width: 400px;
        }
        .rfd {
            &-title {
                text-align: center;
            }
            &-close {
                display: none;
            }
        }
    }
    .rfd {
        &-checkbox {
            .mat-checkbox-layout {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                .mat-checkbox {
                    &-inner-container {
                        display: block;
                        margin: 4px 8px 4px 0;

                    }
                    &-label {
                        display: block;
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 400;
                        white-space: normal;
                    }
                }
            }
        }
        &-action-wrap {
            .rpc-btn-link {
                display: block;
                width: 100%;
            }
        }
    }
}
