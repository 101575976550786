@mixin listing-details-base-common() {
    html.rpc-ld-page-wrap {
        body {
            &.not-authorized {
                .global {
                    @include media-breakpoint("gt-sm") {
                        grid-template-rows: minmax(0, 1fr);
                    }
                    &.global--smart {
                        @include media-breakpoint("gt-sm") {
                            grid-template-rows: auto minmax(0, 1fr);
                        }
                    }
                }
            }
            &:not(.not-authorized) {
                .global {
                    .global-footer {
                        display: none;
                    }
                }
            }
            &.map-fullscreen-wrap {
                .global {
                    .global-footer {
                        display: none !important;
                    }
                }
                .rpc-ld {
                    &-sidebar {
                        z-index: 10;
                    }
                    &-footer {
                        display: none;
                    }
                    &-bottom-actions {
                        display: none;
                    }
                }
            }
            &:not(.hybrid-mode) {
                &:not(.not-authorized) {
                    .global {
                        .global-footer {
                            @include media-breakpoint("gt-sm") {
                                display: block;
                            }
                        }
                    }
                }
                .global {
                    @include media-breakpoint("gt-md") {
                        grid-template-rows: auto minmax(0, 1fr);
                    }
                    listing-details {
                        @include media-breakpoint("gt-sm") {
                            flex-basis: calc(100% - 32px);
                        }
                        @include media-breakpoint("gt-md") {
                            flex-basis: 100%;
                        }
                    }
                }
            }
        }
        .global {
            // grid-template-rows: minmax(0, 1fr);
            grid-template-rows: minmax(0, 1fr) auto;
            @include media-breakpoint("gt-sm") {
                grid-template-rows: minmax(0, 1fr) auto;
            }
            &.global--smart {
                // grid-template-rows: auto minmax(0, 1fr);
                grid-template-rows: auto minmax(0, 1fr) auto;
                @include media-breakpoint("gt-sm") {
                    grid-template-rows: auto minmax(0, 1fr) auto;
                }
            }
            &-bottombar {
                display: none;
                @include media-breakpoint("gt-sm") {
                    display: block;
                }
            }
            listing-details {
                // flex-basis: 100%;
                flex-basis: calc(100% - 1px);
            }
        }
    }
    body {
        &:not(.hybrid-mode) {
            .rpc-ld {
                &-page-box {
                    @include media-breakpoint("gt-md") {
                        grid-template-rows: minmax(0, 1fr);
                    }
                    &.rpc-ld-page-box--toolbar {
                        @include media-breakpoint("gt-md") {
                            grid-template-rows: auto minmax(0, 1fr);
                        }
                    }
                }
                &-toolbar {
                    @include media-breakpoint("gt-md") {
                        display: none;
                    }
                    &--main {
                        @include media-breakpoint("gt-md") {
                            display: block;
                        }
                    }
                }
                &-bottom-actions {
                    @include media-breakpoint("gt-md") {
                        bottom: 16px;
                    }
                }
            }
            .global-bottombar {
                @include media-breakpoint("gt-md") {
                    display: none;
                }
            }
        }
        .rpc-ld {
            &-top-address {
                font-size: 22px;
                line-height: 28px;
                font-weight: 500;
                .rpc-checkbox {
                    .mat-checkbox {
                        &-layout {
                            column-gap: 12px;
                            align-items: flex-start;
                        }
                        &-inner-container {
                            margin: 5px 0 0;
                        }
                    }
                }
                .mat-checkbox-label {
                    font-size: 22px;
                    line-height: 28px;
                    font-weight: 500;
                    white-space: normal;
                }
            }
            &-header {
                &-address {
                    &--checkbox {
                        .mat-checkbox {
                            &-layout {
                                display: grid;
                                grid-template-columns: 20px calc(100% - 26px);
                                column-gap: 6px;
                                align-items: center;
                            }
                            &-label {
                                display: block;
                                font-size: 20px;
                                line-height: 24px;
                                font-weight: 500;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                @include media-breakpoint("gt-xs") {
                                    font-size: 24px;
                                    line-height: 32px;
                                }
                            }
                            &-inner-container {
                                display: block;
                                margin: 0;
                            }
                        }
                    }
                }
                &-price {
                    .price {
                        &-wrap {
                            justify-content: flex-end;
                            flex-direction: row-reverse;
                            @include media-breakpoint("gt-sm") {
                                flex-direction: row;
                            }
                        }
                        &-simple {
                            font-size: 20px;
                            line-height: 24px;
                            font-weight: 500;
                            @include media-breakpoint("gt-xs") {
                                font-size: 24px;
                                line-height: 32px;
                            }
                        }
                    }
                }
                &-info {
                    &-item {
                        &--labels {
                            .listing-labels {
                                &-wrap {
                                    justify-content: flex-start;
                                    align-items: center;
                                    li {
                                        display: flex !important;
                                        &.listing-labels-item-wrap--type {
                                            order: -1;
                                        }
                                    }
                                }
                                &-item {
                                    &-wrap {
                                        &--type {
                                            order: 1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &-media {
                &-labels {
                    .listing-labels-wrap {
                        flex-direction: row;
                        flex-wrap: wrap;
                        align-items: flex-start;
                        column-gap: 10px;
                        row-gap: 10px;
                    }
                }
                &-box {
                    &-item {
                        .main,
                        .broken {
                            &-background-image {
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: cover;
                                background-clip: content-box;
                            }
                        }
                    }
                }
            }
            &-nav {
                &-communication {
                    &-counter {
                        .badge-count {
                            top: -5px;
                            right: -14px;
                            min-width: 0;
                        }
                    }
                }
                &-item {
                    &--removed {
                        .activity-controls-wrapper {
                            display: grid;
                            grid-template-columns: repeat(2, minmax(0, 1fr));
                            column-gap: 10px;
                            align-items: center;
                        }
                    }
                }
                &-actions {
                    &-folder {
                        .rpc-btn-link {
                            display: block;
                            max-width: 100%;
                            .mat-button-wrapper {
                                display: grid;
                                grid-template-columns: 26px calc(100% - 31px);
                                column-gap: 5px;
                                justify-content: start;
                            }
                            &-text {
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                margin-left: 0 !important;
                            }
                        }
                    }
                    .activities-wrap--combined {
                        grid-template-columns: 100%;
                    }
                }
            }
            &-history-tooltip {
                padding-inline: 0 !important;
            }
            &-body {
                &-item {
                    &-title {
                        .badge-count {
                            top: -5px;
                            right: -16px;
                        }
                    }
                }
                &-price-changes,
                &-map-wrap {
                    .mat-tab-group {
                        .mat-tab-body-content {
                            padding-inline: 0;
                        }
                    }
                }
                &-map-wrap {
                    google-map {
                        .map {
                            &-container {
                                position: relative !important;
                                overflow: visible !important;
                            }
                        }
                    }
                    .mat-tab-group {
                        .mat-tab-body-content {
                            padding-bottom: 0;
                        }
                    }
                }
                .rpc-icon-field {
                    align-items: start;
                    &-text {
                        padding-block: 2px;
                    }
                }
            }
            &-chat-button {
                display: block;
                width: 74px;
                height: 74px;
            }
        }
    }
}