@mixin multi-selection-menu-common() {
    &:not(.hybrid-mode) {
        .rpc-multi-selection {
            &--checked {
                @include media-breakpoint("gt-md") {
                    grid-template-columns: auto minmax(0, 1fr);
                    grid-template-areas: "checkbox actions";
                }
            }
        }
    }
    .rpc-multi-selection {
        &-checkbox {
            .mat-checkbox-label {
                padding-right: 10px;
            }
            .mat-checkbox-inner-container {
                margin: auto 8px;
            }
        }
    }
}
