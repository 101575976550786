@use '@angular/material' as mat;
@mixin additional-profiles-tab($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .aditional-profiles-list {
        &-body {
            background-color: mat.get-color-from-palette($background, card);
            border-color: mat.get-color-from-palette($foreground, divider);
        }
        &-empty,
        &-item-email,
        &-item-title {
            color: mat.get-color-from-palette($foreground, secondary-text); 
        }
        &-item {
            &:not(:last-of-type) {
                border-bottom-color: mat.get-color-from-palette($foreground, divider);
            }
        }
    }
}