@mixin new-match-settings-common() {
    .nm-settings {
        &-section {
            &-btn {
                &--icon {
                    .rpc-btn-link {
                        .mat-button-wrapper {
                            flex-direction: row-reverse;
                            column-gap: 10px;
                            .rpc-btn-link-text {
                                margin: 0 !important;
                            }
                        }
                    }
                }
                .rpc-btn-link {
                    padding: 0 9px;
                    min-width: 1px; 
                    &.mat-flat-button {
                        border-width: 1px;
                        border-style: solid;
                    }
                    &-text {
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 400;
                    }
                }
            }
            &-select {
                &--xs {
                    .rpc-select {
                        &.mat-form-field {
                            &.field-outline {
                                .mat-form-field-flex {
                                    padding: 0 10px !important;
                                }
                            }
                        }
                    }
                    .mat-form-field {
                        &.mat-form-field-type-mat-select {
                            .mat-form-field-infix {
                                .mat-select-trigger {
                                    min-width: 40px !important;
                                }
                            }
                        }
                    }
                }
                &--sm {
                    .mat-form-field {
                        &.mat-form-field-type-mat-select {
                            .mat-form-field-infix {
                                .mat-select-trigger {
                                    min-width: 110px !important;
                                }
                            }
                        }
                    }
                }
                .rpc-select {
                    &.mat-form-field {
                        &.field-outline {
                            .mat-form-field-flex {
                                padding: 0 5px;
                            }
                        }
                    }
                }
                .mat-form-field {
                    &-wrapper {
                        padding-bottom: 0 !important;
                        margin: 0;
                    }
                    &-flex {
                        width: auto;
                    }
                    &-subscript-wrapper {
                        display: none;
                    }
                    &.mat-form-field-type-mat-select {
                        .mat-form-field-infix {
                            .mat-select-trigger {
                                width: auto;
                                min-width: 60px;
                            }
                        }
                    }
                }
            }
        }
    }
}
