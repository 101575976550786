@use '@angular/material' as mat;
@mixin listing-details-base($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    & {
        html.rpc-ld-page-wrap & {
            @if ($is-dark) {
                background-color: mat.get-color-from-palette($background, background);
            } @else {
                background-color: mat.get-color-from-palette($background, card);
            }
        }
    }

    .rpc-ld {
        &-header {
            &-info-type {
                &.s {
                    background-color: var(--sales-flag);
                    color: var(--sales-flag-text);
                }
                &.r {
                    background-color: var(--rentals-flag);
                    color: rgba(0,0,0,.87);
                }
            }
            &-crossroad {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
            &-additional-price {
                &-item {
                    &:before {
                        border-left-color: mat.get-color-from-palette($foreground, divider);
                    }
                    &-title {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                    &-icon {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                }
            }
        }
        &-media {
            &-section {
                &-item {
                    background-color: mat.get-color-from-palette($background, card);
                    color: mat.get-color-from-palette($foreground, text);
                    border-color: mat.get-color-from-palette($foreground, divider);
                    &:hover {
                        background-color: mat.get-color-from-palette($primary, default-contrast);
                        color: mat.get-color-from-palette($primary, default);
                    }
                }
            }
        }
        &-nav {
            &-section {
                background-color: mat.get-color-from-palette($background, card);
            }
            &-oh {
                color: mat.get-color-from-palette($foreground, text);
                &:hover {
                    .rpc-ld-nav-oh {
                        &-icon {
                            color: mat.get-color-from-palette($accent, default);
                        }
                    }
                }
                &-label {
                    background-color: mat.get-color-from-palette($primary, default);
                    color: mat.get-color-from-palette($primary, default-contrast);
                }
                &-time,
                &-date,
                &-icon {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                }
                &-link {
                    &-icon {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                }
            }
            &-item {
                &--picked {
                    .rpc-icon-btn {
                        &:not(.mat-button-disabled) {
                            &.active {
                                color: var(--picklist-color);
                                .rpc-icon-btn-icon {
                                    color: currentColor;
                                }
                            }
                        }
                    }
                    &-full {
                        .rpc-btn-link {
                            &:not(.mat-button-disabled) {
                                color: mat.get-color-from-palette($foreground, secondary-text);
                                &.active {
                                    color: var(--picklist-color);
                                }
                                .rpc-btn-link {
                                    &-icon,
                                    &-text {
                                        color: currentColor;
                                    }
                                }
                            }
                        }
                    }
                }
                &--removed {
                    .rpc-btn-link {
                        &:not(.mat-button-disabled) {
                            &:hover,
                            &.active {
                                color: mat.get-color-from-palette($foreground, secondary-text);
                            }
                            .rpc-btn-link {
                                &-icon,
                                &-text {
                                    color: mat.get-color-from-palette($foreground, secondary-text);
                                }
                            }
                        }
                    }
                }
                &--folder {
                    &-full {
                        .rpc-btn-link {
                            &:not(.mat-button-disabled) {
                                &:hover {
                                    color: mat.get-color-from-palette($foreground, secondary-text);
                                }
                                .rpc-btn-link {
                                    &-icon {
                                        color: mat.get-color-from-palette($foreground, secondary-text);
                                    }
                                    &-text {
                                        color: mat.get-color-from-palette($foreground, secondary-text);
                                    }
                                }
                            }
                        }
                    }
                }
                &--history {
                    .rpc-menu-icon-btn {
                        &:not(.mat-button-disabled) {
                            &:hover,
                            &.active {
                                color: mat.get-color-from-palette($accent, default);
                                .rpc-menu-icon-btn-icon {
                                    color: mat.get-color-from-palette($accent, default);
                                }
                            }
                            &.rpc-menu-icon-btn {
                                &--opened {
                                    color: mat.get-color-from-palette($accent, default);
                                    .rpc-menu-icon-btn-icon {
                                        color: mat.get-color-from-palette($accent, default);
                                    }
                                }
                            }
                        }
                    }
                }

            }
            &-communication {
                color: mat.get-color-from-palette($foreground, text);
                &:hover {
                    .rpc-ld-nav-communication {
                        &-icon {
                            color: mat.get-color-from-palette($accent, default);
                        }
                    }
                }
                &.rpc-ld-nav-communication--empty {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                    &:hover {
                        .rpc-ld-nav-communication {
                            &-icon {
                                color: mat.get-color-from-palette($accent, default);
                            }
                        }
                    }
                    .rpc-ld-nav-communication {
                        &-icon {
                            color: mat.get-color-from-palette($foreground, disabled);        
                        }
                    }
                }
                &-icon {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                }
            }
            &-info {
                &-main {
                    .mat-checkbox-layout {
                        .mat-checkbox-label {
                            color: mat.get-color-from-palette($foreground, secondary-text);
                        }
                    }
                }
            }
            &-actions {
                &-folder {
                    .rpc-btn-link {
                        &:not(.mat-accent) {
                            &:hover {
                                .rpc-btn-link {
                                    &-icon {
                                        color: mat.get-color-from-palette($accent, default);
                                        --main-color: #{mat.get-color-from-palette($accent, default)};
                                        --text-color: #{mat.get-color-from-palette($accent, default-contrast)};
                                    }
                                }    
                            }
                            .rpc-btn-link {
                                &-icon {
                                    color: mat.get-color-from-palette($foreground, secondary-text);
                                    --main-color: #{mat.get-color-from-palette($foreground, secondary-text)};
                                }
                            }
                        }
                    }
                }
            }
        }
        &-body {
            &-header {
                .rpc-ld-nav-info {
                    &-btn {
                        color: mat.get-color-from-palette($foreground, text);
                        &:hover {
                            color: mat.get-color-from-palette($accent, default);
                            .rpc-ld-nav-info-btn-icon {
                                color: mat.get-color-from-palette($accent, default);
                            }
                        }
                        &-icon {
                            color: mat.get-color-from-palette($foreground, secondary-text);
                        }
                    }
                }
            }
            &-item {
                &-title {
                    color: mat.get-color-from-palette($foreground, text);
                }
            }
            &-top {
                &-mobile-block {
                    &-item {
                        @if ($is-dark) {
                            background-color: rgb(0 0 0 / 50%);
                        } @else {
                            background-color: rgb(0 0 0 / 5%);
                        }
                        &-icon {
                            color: mat.get-color-from-palette($foreground, secondary-text);
                        }
                    }
                }
                &-item {
                    color: mat.get-color-from-palette($foreground, text);
                    &-icon {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                }
            }
            &-features {
                &-item {
                    &-title {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                    &-text {
                        color: mat.get-color-from-palette($foreground, text);
                    }
                }
            }
            &-map {
                &-box {
                    border-color: mat.get-color-from-palette($foreground, divider);
                }
            }
            &-price-changes {
                .main-table {
                    background-color: transparent;
                }
            }
        }
        &-chat-button {
            .rpc-fab-btn {
                color: var(--similar);
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
            }
        }
    }
}