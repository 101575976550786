@mixin folder-edit-common() {
    &:not(.hybrid-mode) {
        .folder-edit-box {
            .folder-edit-action-wrap {
                @include media-breakpoint("gt-md") {
                    grid-template-columns: minmax(0, 1fr) repeat(2, auto);
                    grid-template-areas: ". cancel action";
                }
            }
        }
    }
    .folder-edit { 
        max-width: 100% !important;
        @include media-breakpoint("gt-xs") {
            max-width: 80vw !important;
        }
        .mat-dialog-container {
            border-radius: 10px;
            padding: 0;
            max-width: 100%;
            width: 400px;
            overflow-x: hidden;
            overflow-y: auto;
        }
        &-box {
            .mat-form-field-appearance-fill {
                .mat-form-field-wrapper {
                    margin: 0;
                }
            }
            .folder-edit-action {
                &-wrap {
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                    grid-template-areas: "cancel action";
                    column-gap: 8px;
                    margin-bottom: 0;
                }
                .rpc-btn-link {
                    display: block;
                    width: 100%;
                }
            }
        }
    }
}