@mixin calendar-view-common() {
    &:not(.hybrid-mode) {
        .apnt-c {
            &-wrap {
                @include media-breakpoint("gt-md") {
                    margin: 0 70px;
                    width: calc(100% - 140px);
                }
            }
            &-calendar {
                @include media-breakpoint("gt-md") {
                    border-radius: 10px 0 0 0;
                }
                .mat-form-field {
                    &-suffix {
                        .mat-icon-button {
                            @include media-breakpoint("gt-md") {
                                border-radius: 10px 0 0 0;
                            }
                        }
                    }
                }
            }
            &-week {
                &-wrap {
                    @include media-breakpoint("gt-md") {
                        border-radius: 0 10px 0 0;
                        border-width: 1px;
                        border-style: solid;
                        border-left: none;
                        border-bottom: none;
                    }
                }
                &-item {
                    &-day {
                        &.badge-wrap {
                            .badge-count {
                                position: static;
                                display: block;
                            }
                        }
                    }
                }
            }
            &-box {
                &-wrap {
                    @include media-breakpoint("gt-md") {
                        flex-basis: calc(100% - 94px);
                        border-width: 1px;
                        border-style: solid;
                        border-top: none;
                        border-radius: 0 0 10px 10px;
                    }
                }
            }
            &-time {
                @include media-breakpoint("gt-md") {
                    width: 85px;
                }
            }
            &-days {
                &-wrap {
                    @include media-breakpoint("gt-md") {
                        width: calc(100% - 85px);
                    }
                }
            }
        }
    }
    &.calendar-view-wrap {
        .global-content {
            &-wrap {
                &.global-toolbar-container {
                    .global-content,
                    .apnt-wrap {
                        display: block !important;
                        height: 100% !important;
                    }
                    .apnt-wrap {
                        padding-bottom: 51px;
                        @include media-breakpoint("gt-xs") {
                            padding-bottom: 0;
                        }
                        &--empty {
                            padding-bottom: 0;
                        }
                    }
                    .global-content {
                        overscroll-behavior-y: none;
                        overflow-y: initial !important;
                        overflow-x: initial;
                        overflow-anchor: initial;
                        -ms-overflow-style: initial;
                        touch-action: initial;
                        -ms-touch-action: initial;
                        -webkit-overflow-scrolling: initial;
                    }
                }
                my-appointments {
                    display: initial;
                    flex: 0 0 auto;
                }
            }
        }
    }

    .apnt-c {
        &-calendar {
            .rpc-date-wrap {
                &--hidden {
                    display: block;
                    height: 100%;
                }
            }
            .mat-form-field {
                &-suffix {
                    .mat-icon-button {
                        border-radius: 0;
                    }
                    .rpc-datepicker-icon {
                        font-size: 24px;
                        @include media-breakpoint("gt-xs") {
                            font-size: 36px;
                        }
                    }
                }
            }
        }
        &-week {
            &-btn {
                .rpc-btn-link {
                    min-width: 1px;
                    width: 40px;
                    -webkit-border-radius: 8px;
                    border-radius: 8px;
                    .badge-count {
                        top: -4px;
                        right: -7px;
                    }
                }
            }
            &-item-day {
                .badge-count {
                    top: -4px;
                    right: -15px;
                }
            }
        }
        &-day-appointment {
            &.mat-calendar-body-cell {
                position: relative;
                &:after {
                    position: absolute;
                    display: block;
                    content: "";
                    top: 6px;
                    right: 9px;
                    left: auto;
                    font-size: 6px;
                    width: 1em;
                    height: 1em;
                    -webkit-border-radius: 50%;
                    border-radius: 50%;
                    z-index: 5;
                }
            }
        }
    }
}
