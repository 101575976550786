@mixin listing-share-common() {
    .share-listing-modal {
        position: absolute !important;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        max-width: none !important;
        @include media-breakpoint("gt-xs") {
            position: static !important;
            max-width: 90% !important;
        }
        .mat-dialog-container {
            padding: 0;
            border-radius: 0;
            @include media-breakpoint("gt-xs") {
                border-radius: 10px;
                width: 560px;
                max-width: 100%;
                max-height: calc(var(--vh) * 0.85);
            }
        }
    }
    .rpc-share {
        &-wrap {
            &-dropdown {
                min-width: auto;
                width: 100%;
                max-width: 320px;
                border-radius: 10px;
                border-width: 1px;
                border-style: solid;
                .mat-menu-content {
                    padding: 10px 0 0;
                }
                .rpc-share {
                    &-header {
                        border: none;
                        padding-bottom: 0;
                    }
                    &-body {
                        padding-bottom: 0;
                    }
                }
            }
        }
        &-field {
            &--suffix {
                .mat-form-field {
                    &-infix {
                        padding-right: 5px !important;
                    }
                    &-suffix {
                        top: -9px;
                    }
                }
                .rpc-share-field-copy-btn {
                    margin-right: -5px;
                    .mat-button-wrapper {
                        text-transform: uppercase;
                    }
                }
            }
            .mat-form-field {
                &-wrapper {
                    padding-bottom: 0px !important;
                    margin-bottom: 3px !important;
                    .mat-form-field {
                        &-underline {
                            position: static;
                        }
                        &-ripple {
                            height: 1px;
                        }
                    }
                }
                &-infix {
                    width: 100%;
                    textarea {
                        &.mat-input-element {
                            max-height: 350px;
                            min-height: 54px;
                        }
                    }
                }
                &-subscript {
                    &-wrapper {
                        min-height: 13px;
                        margin-top: 3px !important;
                    }
                }
            }
        }
        &-form {
            &-action {
                .rpc-btn-link {
                    display: block;
                    width: 100%;
                }
            }
        }
    }
}
