@mixin not-authorized-toolbar-common() {
    .not-authorized-toolbar {
        &-logo {
            .rpc-logo {
                grid-template-columns: 32px minmax(0, 1fr);
                column-gap: 8px;
                &-img {
                    width: 32px;
                    height: 32px;
                }
                &-name {
                    font-size: 16px;
                    line-height: 19px;
                    font-weight: 500;
                }
                &-company {
                    font-size: 10px;
                    line-height: 12px;
                    font-weight: 400;
                }
            }
        }
        &-btn {
            .rpc-btn-link-text {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
            }
        }
    }
}