@mixin remove-listing-common() {
    .remove-dialog-wrap {
        max-width: 100vw !important;
        width: 100%;
        @include media-breakpoint("gt-xs") {
            max-width: 80vw !important;
            width: auto;
        }
        .mat-dialog-container {
            padding: 0;
            border-radius: 10px;
            @include media-breakpoint("gt-xs") {
                overflow: visible;
                -webkit-overflow-scrolling: initial;
            }
        }
    }
    .remove-listing {
        &-list {
            &-wrap {
                &--single {
                    .f-listing {
                        &-title {
                            display: none;
                        }
                    }
                }
                .f-listing {
                    &-list {
                        overflow-y: visible;
                        max-height: none;
                    }
                    &-item {
                        &:last-of-type {
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }
}
