@mixin rpc-input-common() {
    .mat-form-field {
        &-disabled {
            .mat-form-field {
                &-flex {
                    pointer-events: none;
                }
                &-autofill-control {
                    opacity: 0.5;
                }
            }
        }
        &-suffix,
        &-prefix {
            display: flex;
        }
        &-prefix {
            margin-right: 5px;
            margin-left: -6px;
        }
        &-subscript-wrapper {
            position: static;
            padding: 0 !important;
        }
        &.field {
            &-fill,
            &-legacy {
                &.mat-form-field-can-float {
                    &.mat-form-field-should-float {
                        .mat-form-field-label {
                            margin-top:-0.5em;
                        }
                    }
                    .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
                        margin-top:-0.5em;
                    }
                }
                .mat-form-field {
                    &-flex {
                        padding: 8px 15px 0;
                    }
                    &-underline {
                        position: static;
                    }
                    &-ripple {
                        height: 1px;
                    }
                    &-label {
                        margin-top: -0.4em;
                    }
                }
            }
            &-outline {
                .mat-form-field {
                    &-flex {
                        padding: 0 1.2em 0 1.2em;
                    }
                    &-infix {
                        padding: 0.25em 0 0.75em !important;
                    }
                    &-outline {
                        &-start,
                        &-end,
                        &-gap {
                            border-width: 1px;
                        }
                        &-start {
                            min-width: 5px;
                        }
                    }
                    &-label {
                        top: 1.35em !important;
                    }
                    &-prefix {
                        top: .35em;
                    }
                }
            }
        }
        &-should-float {
            &.field {
                &-outline {
                    .mat-form-field {
                        &-label {
                            margin-top: 3px !important;
                        }
                    }
                }
            }
        }
    }
}
