@use "@angular/material" as mat;
@mixin on-market-secondary-toolbar($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    &:not(.hybrid-mode) {
        .on-market-secondary-toolbar {
            @include media-breakpoint("gt-md") {
                box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.06);
            }
            &.on-market-secondary-toolbar--top {
                @include media-breakpoint("gt-md") {
                    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0);
                }
                .on-market-secondary-toolbar {
                    &-top,
                    &-bottom {
                        @include media-breakpoint("gt-md") {
                            background-color: rgb(0 0 0 / 0);
                        }
                    }
                }
            }
        }
    }
    .on-market-secondary-toolbar {
        &-top {
            background-color: mat.get-color-from-palette($background, card);
            border-bottom-color: mat.get-color-from-palette($foreground, divider);
        }
        &-title {
            &-counter {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
        &-bottom {
            background-color: mat.get-color-from-palette($background, card);
            border-bottom-color: mat.get-color-from-palette($foreground, divider);
            box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0);
        }
    }
    .saved-search-option {
        &-note {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
    }
}