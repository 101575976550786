@use "@angular/material" as mat;
@mixin general-info-page($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .general-profile-page-toolbar {
        &:not(.general-profile-page-toolbar--edit) {
            background-color: mat.get-color-from-palette($background, hover);
            &:after {
                background-color: rgb(0 0 0 / 54%);
            }
            .general-profile-page-toolbar {
                &-title {
                    color: #fff;
                }
                &-left,
                &-right {
                    .rpc-icon-btn {
                        &:not(.mat-button-disabled) {
                            color: #fff;
                            &:hover {
                                .rpc-icon-btn-icon {
                                    color: #fff;
                                }
                            }
                            .rpc-icon-btn-icon {
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
        &.general-profile-page-toolbar--edit {
            background-color: mat.get-color-from-palette($background, card);
            border-bottom-color: mat.get-color-from-palette($foreground, divider);
            .general-profile-page-toolbar-title {
                color: mat.get-color-from-palette($foreground, text);
            }
        }
    }
}