@mixin landing-section-common() {
    &:not(.hybrid-mode) {
        .landing-section {
            &.landing-section--action {
                .landing-section-title {
                    @include media-breakpoint("gt-md") {
                        grid-template-columns: 100%;
                        grid-template-areas: "text";
                    }
                    &-action {
                        @include media-breakpoint("gt-md") {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .landing-section {
        .landing-section {
            &-title-action {
                .rpc-btn-link {
                    padding: 0 0 0 5px;
                    @include media-breakpoint("gt-xs") {
                        padding: 0 10px;
                    }
                    @include media-breakpoint("gt-md") {
                        padding: 0 16px;
                    }
                    .mat-button-wrapper {
                        column-gap: 5px;
                        flex-direction: row-reverse;
                    }
                    .rpc-btn-link {
                        &-icon {
                            font-size: 20px;
                            & ~ .rpc-btn-link-text {
                                margin: 0;
                            }
                        }
                        &-text {
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 400;
                        }
                    }
                }
            }
            &-block {
                .rpc-carousel {
                    overflow: visible;
                    @include media-breakpoint("gt-md") {
                        overflow: hidden;
                    }
                }
            }
        }
    }
}