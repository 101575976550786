@use '@angular/material' as mat;
@mixin toastr-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    html:not(.hybrid-mode) {
        .toast-container {
            .toast-custom {
                &.ngx-toastr {
                    @include media-breakpoint("gt-md") {
                        box-shadow: none;
                        background: none;
                    }
                }
            }
        }
    }
    .toast {
        &-error {
            background-color: var(--t-error-500);
            color: var(--t-error-500-contrast);
        }
        &-success {
            background-color: var(--t-success-500);
            color: var(--t-success-500-contrast);
        }
        &-info {
            background-color: var(--t-info-500);
            color: var(--t-info-500-contrast);
        }
        &-warning {
            background-color: var(--t-warn-500);
            color: var(--t-warn-500-contrast);
        }
        &-container {
            .ngx-toastr {
                background-image: none;
                box-shadow: none;
                &.toast-custom {
                    background: none;
                }
                .toast-close-button {
                    color: #fff;
                    &:focus {
                        color: #fff;
                    }
                    &:hover {
                        color: rgba(255,255,255,.7);
                    }
                }
            }
        }
    }
}
html:not(.hybrid-mode) {
    .toast-container {
        @include media-breakpoint("gt-md") {
            top: 29px;
            bottom: auto;
            left: auto;
            right: 29px;
            grid-template-columns: 100%;
            row-gap: 30px;
            justify-content: end;
            justify-items: end;
            align-items: start;
        }
        .ngx-toastr {
            @include media-breakpoint("gt-md") {
                padding: 16px;
                width: 300px;
                max-width: none;
            }
            &.ng-trigger-flyInOut {
                @include media-breakpoint("gt-md") {
                    transform: translateY(0px);
                }
            }
            &.toast-custom {
                @include media-breakpoint("gt-md") {
                    padding: 0px;
                    width: 100%;
                    max-width: 560px;
                }
            }
        }
    }
}
.toast-container {
    position: absolute;
    top: auto;
    bottom: 40px;
    right: 10px;
    left: 10px;
    display: grid;
    grid-template-columns: auto;
    row-gap: 10px;
    justify-content: center;
    justify-items: center;
    align-items: end;
    .ngx-toastr {
        display: grid;
        grid-template-columns: minmax(0, 1fr) auto;
        column-gap: 4px;
        align-items: start;
        border-radius: 8px;
        width: auto;
        max-width: 300px;
        padding: 10px 16px;
        margin: 0;
        overflow: visible;
        transform: translateY(0px);
        transition: transform .5s cubic-bezier(0.35, 0, 0.25, 1);
        will-change: transform;
        &.ng-trigger-flyInOut {
            transform: translateY(-30px);
        }
        .toast {
            &-close-button {
                grid-area: 1/2/2/3;
                position: static;
                display: flex;
                justify-content: center;
                align-items: center;
                float: none;
                width: 24px;
                height: 24px;
                font-weight: 300;
                text-shadow: none;
                &:hover,
                &:focus {
                    opacity: 1;
                }
            }
            &-message {
                grid-area: 1/1/2/2;
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
                letter-spacing: .3px;
                padding: 2px 0;
                user-select: none;
            }
        }
    }
    .toast-custom {
        &.ngx-toastr {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            border-radius: 0;
            padding: 0;
            max-width: 650px;
            & > div {
                display: grid;
                grid-template-columns: 100%;
                justify-items: end;
                align-items: start;
            }
        }
    }
}