@mixin general-info-page-common() {
    &.hybrid-mode {
        .general-profile-page {
            .rpc-scroll-to-top-wrap {
                bottom: 64px;
                @include media-breakpoint("gt-xs") {
                    bottom: 64px;
                }
            }
        }
    }
    .general-profile-page {
        .general-profile-page {
            &-toolbar {
                &-left,
                &-right {
                    .rpc-icon-btn {
                        border-radius: 4px;
                    }
                    .rpc-btn-link {
                        padding: 0 10px;
                        min-width: auto;
                        .rpc-btn-link-text {
                            font-size: 16px;
                            line-height: 24px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}