@mixin folder-details-common() {
    &:not(.hybrid-mode) {
        .rpc-menu-wrap {
            .folder-menu {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
    .rpc-folder-details {
        &-modal {
            max-width: 100% !important;
            .mat-dialog-container {
                border-radius: 10px;
                padding: 0;
                width: 590px;
                max-width: 100%;
                max-height: calc(var(--vh) * 0.85);
            }
            .folder-menu {
                row-gap: 0;
                padding-top: 6px;
            }
        }
        &-bottomsheet {
            &.mat-bottom-sheet-container {
                border-radius: 10px 10px 0 0;
                padding: 0;
                max-height: calc(var(--vh) * 0.85);
            }
        }
    }
    .folder {
        &-menu {
            &-footer-item {
                .rpc-btn-link {
                    border-radius: 0;
                    width: 100%;
                    &-text {
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 400;
                    }
                    .mat-button-wrapper {
                        justify-content: flex-start;
                    }
                }
            }
        }
        &-default-toggle {
            .mat-slide-toggle {
                &-label {
                    align-items: flex-start;
                }
                &-content {
                    flex: 0 1 100%;
                }
                &-bar {
                    margin-top: 3px;
                }
            }
        }
    }
}