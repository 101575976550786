@mixin change-password-common() {
    .change-password {
        &-item {
            .mat-form-field-wrapper {
                padding-bottom: 0 !important;
                margin : 0 !important;
            }
        }
        &-btn {
            .rpc-btn-link {
                display: block;
                width: 100%;
                @include media-breakpoint("gt-xs") {
                    width: auto;
                }
            }
        }
    }
}
