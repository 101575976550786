@mixin portfolio-common() {
    &:not(.hybrid-mode) {
        .portfolio-page {
            &-toolbar {
                @include media-breakpoint("gt-md") {
                    display: none;
                }
            }
        }
    }
}