@mixin toolbar-base-common() {
    .toolbar {
        &-secondary {
            &--minimized {
                .header {
                    @include media-breakpoint("gt-sm") {
                        grid-template-columns: calc(100% - 232px) auto auto;
                        grid-template-areas: "filters btns views";
                        row-gap: 0px;
                        padding: 5px 20px;
                        width: 100%;
                        margin: 0;
                    }
                    &--appointments {
                        @include media-breakpoint("gt-sm") {
                            grid-template-columns: minmax(0, 1fr) auto auto;
                            grid-template-areas: "filters nav views";
                        }
                    }
                    &-title {
                        @include media-breakpoint("gt-sm") {
                            display: none;
                        }
                    }
                    &-btn-wrap {
                        .rpc-btn-link,
                        .rpc-menu-btn-link {
                            @include media-breakpoint("gt-sm") {
                                width: 40px;
                                height: 40px;
                                min-width: 1px;
                                border-radius: 50%;
                                padding: 0;
                            }
                            &-text {
                                @include media-breakpoint("gt-sm") {
                                    display: none;
                                }
                            }
                            &-trigger {
                                @include media-breakpoint("gt-sm") {
                                    display: none;
                                }
                            }
                        }
                    }
                    .rpc-divider--vertical {
                        font-size: 30px;
                    }
                    .hide-minimized {
                        display: none;
                    }
                }
            }
        }
    }
    .header {
        .hide-minimized {
            display: none;
            @include media-breakpoint("gt-sm") {
                display: block;
            }
        }
        &-btn-wrap {
            .rpc-btn-link,
            .rpc-menu-btn-link {
                width: 40px;
                height: 40px;
                min-width: 1px;
                border-radius: 50%;
                padding: 0;
                @include media-breakpoint("gt-sm") {
                    min-width: 64px;
                    border-radius: 4px;
                    padding: 0 16px;
                    width: auto;
                }
                &-text {
                    display: none;
                    @include media-breakpoint("gt-sm") {
                        display: block;
                    }
                }
                &-trigger {
                    display: none;
                    @include media-breakpoint("gt-sm") {
                        display: block;
                    }
                }
            }
        }
    }
}
