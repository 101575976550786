@use '@angular/material' as mat;
@mixin saved-search-form($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .saved-search {
        &-preset {
            &-checkbox {
                &-note {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                }
            }
            &-folder-form {
                .folder-edit {
                    &-form {
                        background-color: mat.get-color-from-palette($background, background);
                        .mat-form-field-appearance-outline {
                            .mat-form-field-outline,
                            .mat-form-field-outline-thick {
                                background-color: mat.get-color-from-palette($background, card);
                            }
                        }
                    }
                }
            }
        }
        &-option {
            &-header {
                background-color: mat.get-color-from-palette($background, card);
                &-divider {
                    border-bottom-color: mat.get-color-from-palette($foreground, divider);
                }
                .saved-search-preset-folder {
                    .rpc-btn-link {
                        &:not(.mat-button-disabled) {
                            &:hover {
                                .rpc-btn-link-text {
                                    color: mat.get-color-from-palette($foreground, text);
                                }
                            }
                            .rpc-btn-link-text {
                                color: mat.get-color-from-palette($foreground, text);
                            }
                        }
                    }
                }
            }
            &-list {
                .saved-search-option-folder {
                    &.saved-search-option-folder--default {
                        .rpc-btn-link {
                            &:not(.mat-button-disabled) {
                                &:hover {
                                    .rpc-btn-link-text {
                                        color: mat.get-color-from-palette($foreground, text);
                                    }
                                }
                                .rpc-btn-link {
                                    &-text {
                                        color: mat.get-color-from-palette($foreground, secondary-text);
                                    }
                                }
                            }
                        }
                    }
                    .rpc-btn-link {
                        &:not(.mat-button-disabled) {
                            &:hover {
                                .rpc-btn-link-text {
                                    color: mat.get-color-from-palette($foreground, text);
                                }
                            }
                            .rpc-btn-link {
                                &-text {
                                    color: mat.get-color-from-palette($foreground, text);
                                }
                                &-icon {
                                    color: mat.get-color-from-palette($foreground, secondary-text);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
