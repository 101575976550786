@use "@angular/material" as mat;
@mixin additional-profile-page($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .profile-header {
        &-icon {
            color: mat.get-color-from-palette($accent, default);
        }
        &-subtitle {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
    }
}
