@use "@angular/material" as mat;
@mixin profile-phone-list($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .profile-phone-list {
        &-item {
            &:not(:last-of-type) {
                border-bottom-color: mat.get-color-from-palette($foreground, divider);
            }
            &-option {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
        &-empty {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
    }
}
