@mixin mortgage-report-common() {
    &:not(.hybrid-mode) {
        .mr {
            &-wrap {
                @include media-breakpoint("gt-md") {
                    grid-template-rows: 100%;
                }
                &.mr-wrap--authorised {
                    .mr-body {
                        .mr-form-title {
                            @include media-breakpoint("gt-md") {
                                display: block;
                            }
                        }
                    }
                }
            }
            &-toolbar {
                @include media-breakpoint("gt-md") {
                    display: none;
                }
            }
        }
    }
    .mr-wrap {
        &.mr-wrap--authorised {
            .mr-body {
                .mr-form-title {
                    display: none;
                }
            }
        }
    }
}