@mixin appointments-secondary-toolbar-common() {
    &:not(.hybrid-mode) {
        .appointments-secondary-toolbar {
            &.appointments-secondary-toolbar--top {
                .appointments-secondary-toolbar {
                    &-content {
                        @include media-breakpoint("gt-md") {
                            row-gap: 16px;
                            margin: 0 54px;
                        }
                    }
                    &-top,
                    &-bottom {
                        @include media-breakpoint("gt-md") {
                            border: none;
                        }
                    }
                    &-top {
                        @include media-breakpoint("gt-md") {
                            grid-template-columns: minmax(0, 1fr) min-content;
                            grid-template-areas: 
                                "title switch"
                                "filter nav-lg";
                            padding: 32px 16px 0;
                        }
                    }
                    &-title {
                        @include media-breakpoint("gt-md") {
                            display: grid;
                        }
                    }
                    &-bottom {
                        @include media-breakpoint("gt-md") {
                            padding: 0 16px;
                        }
                    }
                }
            }
            &-top {
                @include media-breakpoint("gt-md") {
                    grid-template-columns: minmax(0, 1fr) auto auto;
                    grid-template-areas: "filter nav-lg switch";
                    column-gap: 16px;
                    padding: 6px 24px 5px;
                }
                &-nav {
                    &--xs {
                        @include media-breakpoint("gt-md") {
                            display: none;
                        }
                    }
                    &--lg {
                        @include media-breakpoint("gt-md") {
                            display: flex;
                        }
                    }
                }
                &-switch {
                    &-mobile {
                        @include media-breakpoint("gt-md") {
                            display: none;
                        }
                    }
                    &-item {
                        @include media-breakpoint("gt-md") {
                            display: block;
                        }
                    }
                }
            }
            &-bottom {
                @include media-breakpoint("gt-md") {
                    grid-template-columns: minmax(0, 1fr) auto;
                    border-bottom: 0;
                    padding: 6px 24px;
                }
            }
            &-select {
                &-divider {
                    @include media-breakpoint("gt-md") {
                        display: block;
                    }
                }
            }
        }
    }

    .appointments-secondary-toolbar {
        &-top {
            &-nav {
                &--xs {
                    .appointments-secondary-toolbar-top-nav-btn {
                        .rpc-btn-link {
                            .rpc-btn-link-text {
                                font-weight: 500;
                            }
                        }
                    }
                }
                &--lg {
                    .appointments-secondary-toolbar-top-nav {
                        &-icon-btn {
                            .rpc-icon-btn {
                                line-height: 38px;
                                border-width: 1px;
                                border-style: solid;
                                border-radius: 10px;
                                &.badge-wrap {
                                    .badge-count {
                                        top: -8px;
                                        right: -10px;
                                    }
                                }
                            }
                        }
                        &-btn {
                            .rpc-btn-link {
                                border-radius: 10px;
                                &.badge-wrap {
                                    .badge-count {
                                        top: -10px;
                                        right: -10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .filter-dropdown {
        .filter-menu-title-wrap {
            display: none;
        }
    }
}
