@use "@angular/material" as mat;
@mixin profile-edit-email-list($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .profile-edit-email {
        &-item {
            border-bottom-color: mat.get-color-from-palette($foreground, divider);
            &-header-label {
                background-color: rgb(255 255 255 / 100%);
                color: mat.get-color-from-palette($accent, default);
                &:before {
                    background-color: mat.get-color-from-palette($accent, default, 0.1);
                }
            }
            &-body-hint {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
    }
}