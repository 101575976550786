@mixin saved-search-info-common() {
    &:not(.hybrid-mode) {
        .saved-search-info {
            @include media-breakpoint("gt-md") {
                grid-template-columns: 48px minmax(0, 1fr);
                grid-template-areas:
                    "icon title"
                    "icon subtitle";
                padding: 20px 16px 20px 20px;
            }
            &--editable {
                @include media-breakpoint("gt-md") {
                    grid-template-columns: 48px minmax(0, 1fr) 40px;
                    grid-template-areas:
                        "icon title edit"
                        "icon subtitle edit";
                }
            }
            &-icon-wrap {
                @include media-breakpoint("gt-md") {
                    display: flex;
                }
            }
            &-title {
                @include media-breakpoint("gt-md") {
                    grid-template-columns: auto min-content;
                }
                &-text {
                    @include media-breakpoint("gt-md") {
                        grid-area: initial;
                    }
                }
                &-label {
                    @include media-breakpoint("gt-md") {
                        grid-area: initial;
                    }
                    &-text {
                        @include media-breakpoint("gt-md") {
                            display: block;
                        }
                    }
                    &-icon {
                        @include media-breakpoint("gt-md") {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .saved-search-info {
        &-dropdown {
            &.mat-menu-panel {
                @include media-breakpoint("gt-xs") {
                    max-width: 500px;
                }
            }
        }
        &-divider {
            @include media-breakpoint("gt-xs") {
                margin: 0 20px;
            }
        }
    }
}
