@use '@angular/material' as mat;
@mixin appointments-secondary-toolbar($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    &:not(.hybrid-mode) {
        .appointments-secondary-toolbar {
            @include media-breakpoint("gt-md") {
                box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.06);
            }
            &.appointments-secondary-toolbar--top {
                @include media-breakpoint("gt-md") {
                    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0);
                }
                .appointments-secondary-toolbar {
                    &-top,
                    &-bottom {
                        @include media-breakpoint("gt-md") {
                            background-color: rgb(0 0 0 / 0);
                        }
                    }
                }
            }
        }
    }
    .appointments-secondary-toolbar {
        &-top {
            background-color: mat.get-color-from-palette($background, card);
            border-bottom-color: mat.get-color-from-palette($foreground, divider);
            &-nav {
                &--lg {
                    .appointments-secondary-toolbar-top-nav {
                        &-icon-btn {
                            .rpc-icon-btn {
                                &:not(.mat-button-disabled) {
                                    background-color: mat.get-color-from-palette($background, card);
                                    border-color: mat.get-color-from-palette($foreground, divider);
                                }
                                &.mat-button-disabled {
                                    background-color: rgb(0 0 0 / 0);
                                }
                            }
                        }
                        &-btn {
                            .rpc-btn-link {
                                &:not(.mat-button-disabled) {
                                    background-color: mat.get-color-from-palette($background, card);
                                    border-color: mat.get-color-from-palette($foreground, divider);
                                }
                                &.mat-button-disabled {
                                    background-color: rgb(0 0 0 / 0);
                                }
                            }
                        }
                    }
                }
            }
        }
        &-title {
            &-text {
                color: mat.get-color-from-palette($foreground, text);
            }
            &-note {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
        &-bottom {
            background-color: mat.get-color-from-palette($background, card);
            border-bottom-color: mat.get-color-from-palette($foreground, divider);
            box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0);
        }
    }
}