@mixin m-calculator-summary-common() {
    .mc-summary {
        &-share-dialog {
            .mat-dialog-container {
                border-radius: 10px;
                height: auto;
            }
        }
        &-chart-wrap {
            .dc-wrap {
                font-size: 250px;
                @include media-breakpoint('gt-sm') {
                    font-size: 330px;
                }
            }
        }
    }
}