@mixin external-listings-dialog-common() {
    .external-listings-dailog {
        position: absolute !important;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        max-width: none !important;
        @include media-breakpoint("gt-xs") {
            position: static !important;
            max-width: 738px !important;
        }
        .mat-dialog-container {
            padding: 0;
            @include media-breakpoint("gt-xs") {
                max-height: calc(var(--vh) * 0.85);
            }
        }
    }
    .el-dialog {
        &-spinner {
            .mp-splash {
                pointer-events: all;
            }
        }
        &-header-action {
            .rpc-btn-link-text {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
            }
        }
        &-info {
            .rpc-info {
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                border-left-width: 3px;
                border-left-style: solid;
                border-radius: 0;
                padding: 16px 20px 16px 17px;
            }
        }
        &-form {
            .rpc-file-title {
                text-align: center;
            }
            .rpc-textarea.mat-form-field .mat-input-element {
                min-height: 72px;
            }
        }
    }
}